import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useNavigate,useParams } from 'react-router-dom';
import Swal from 'sweetalert2'

const EditAddress = (props) => {

  const [loading, setLoading] = useState(false);
  const { add_id } = useParams();

  useEffect(() => {
    setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);
  }, []);

  
  useEffect(() => {
    axios
      .get("edit-customer-address/" + add_id)
      .then(function (resp) {
        setLoading(false);
        console.log(resp.data.data[0]);
        setUsername(resp.data.data[0].name);
        setEmail(resp.data.data[0].email);
        setContact(resp.data.data[0].phone);
        if(resp.data.data[0].shipping_id=='inside_dhaka'){
          setCity('inside_dhaka');
        }
        else{
          setCity('outside_dhaka');
        }
        setArea(resp.data.data[0].shipping_area.area_name);
        // console.log(area);
        // setAreaId(resp.data.data[0].area_id);
        setZip(resp.data.data[0].zip);
        // let [defaultValue, setDefault] = useState(false);
        setAddress(resp.data.data[0].address);
      })
      .catch((err) => {
        console.log("error : " + err);
      });
  }, [props.customer_id]);

  let navigate = useNavigate();
  const [errorList,setError]= useState([]); 
  let [username, setUsername] = useState("");
  let [email, setEmail] = useState("");
  let [contact, setContact] = useState("");
  let [city, setCity] = useState("inside_dhaka");
  let [area, setArea] = useState("");
  let [areaID, setAreaId] = useState("");
  let [zip, setZip] = useState("");
  let [defaultValue, setDefault] = useState(false);
  let [address, setAddress] = useState("");
  const [shippingZones, setShippingZones] = useState([]);

  useEffect(() => {
    axios.get("get-shipping-zones")
      .then(resp => {
        setShippingZones(resp.data.shipping_zones);
      }).catch(err => {
        console.log(err);
      });
  }, []);

  const handleSubmit = (event) => {
    var obj = {
        name: username,
        customer_id: props.customer_id,
        email: email,
        phone: contact,
        address: address,
        city: city,
        zip: zip,
        area: area,
        area_id:areaID,
        shipping_id:0,
        is_default:defaultValue
    };
    if(city=='inside_dhaka'){
      obj.shipping_id=1;
      }
      else if(city=='outside_dhaka'){
        obj.shipping_id=2;
      }
// console.log(props.customer_id);
// console.log(obj);
    axios
      .put("update-customer-address/"+add_id, obj)
      .then(function (resp) {
        // console.log(resp.data);
        var data = resp.data;
        // console.log(data);
        if (data.message) {
            Swal.fire({
                customClass: {
                    icon: 'mt-4'
                },
                position: 'center',
                icon: 'success',
                title: data.message,
                showConfirmButton: true,
            });
            navigate("/customer/address");
            window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.errors)
      });
    event.preventDefault();
  };
  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <div className="tab-content account dashboard-content pl-md-50">
        <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
          <div className="card">
            <div className="card">
              <div className="card-header">
                <h5>Edi Address</h5>
              </div>

              <div className="card-body">
                <form onSubmit={(e) => { handleSubmit(e); }}>
                  <div className="form-group">
                    <label htmlFor="name" className="required">
                      Full Name:
                    </label>{" "}
                    <input
                      id="name"
                      type="text"
                      name="name"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Enter your full name"
                      className="form-control square"
                    />
                    {/* <span className='text-danger'>{errorList.name}</span> */}
                  </div>{" "}
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>{" "}
                    <input id="email" type="email" name="email" value={email}
                                      onChange={(e) => setEmail(e.target.value)} placeholder="your-email@domain.com" className="form-control square" />
                  </div>{" "}
                  <div className="form-group">
                    <label htmlFor="phone" className="required">
                      Phone:
                    </label>{" "}
                    <input id="phone" type="text" name="phone" value={contact} onChange={(e) => setContact(e.target.value)} className="form-control square" />
                    {/* <span className='text-danger'>{errorList.phone}</span> */}
                  </div>{" "}
                  <div className="form-group ">
                    <label htmlFor="city" className="required">
                      City:
                    </label>{" "}
                    <select style={{ height: '64px' }} className="form-control address-control-item address-control-item-required"
                      id="city"
                      name="city"
                      value={city}
                      required
                      onChange={(e) => { setCity(e.target.value); }}
                    >
                      <option>Your City</option>
                      <option value={'inside_dhaka'}>Inside Dhaka</option>
                      <option value={'outside_dhaka'}>Outside Dhaka</option>
                    </select>
                    <span className='text-danger'>{errorList.city}</span>
                  </div>{" "}
                  <div className="form-group ">
                    <label htmlFor="area" className="required">
                      Area:
                    </label>{" "}
                    {city === 'inside_dhaka' && (
                      <div className="form-group ">
                        <select style={{ height: '64px' }} className="form-control address-control-item address-control-item-required"
                          name="area"
                          required
                          value={area}
                          onChange={(e) => {setArea(e.target.value);setAreaId(e.target.childNodes[e.target.selectedIndex].getAttribute('areaId'));}}
                        >
                          <option value={null}>Select Area</option>
                          {shippingZones &&
                            shippingZones.map((srvzn, index) => {
                              if (srvzn.shipping_rule_id === 1) {
                                return <option key={index} areaId={srvzn.id} value={srvzn.area_name}>{srvzn.area_name}</option>;
                              }
                            })}
                        </select>
                      </div>
                    )}
                    {city === 'outside_dhaka' && (
                      <div className="form-group ">
                        <select style={{ height: '64px' }} className="form-control address-control-item address-control-item-required"
                          name="area"
                          value={area}
                          required
                          onChange={(e) => {setArea(e.target.value);setAreaId(e.target.childNodes[e.target.selectedIndex].getAttribute('areaId'));}}
                        >
                          <option value={null}>Select Area</option>
                          {shippingZones &&
                            shippingZones.map((srvzn, index) => {
                              if (srvzn.shipping_rule_id === 2) {
                                return <option key={index} areaId={srvzn.id} value={srvzn.area_name}>{srvzn.area_name}</option>;
                              }
                            })}
                        </select>
                      </div>
                    )}
                    <span className='text-danger'>{errorList.area}</span>
                  </div>{" "}
                  <div className="form-group ">
                    <label htmlFor="zip" className="">
                      Zip:
                    </label>{" "}
                    <input id="zip" type="text" name="zip" value={zip} onChange={(e) => setZip(e.target.value)} placeholder="Enter your city" className="form-control square" />
                  </div>{" "}
                  <div className="form-group">
                    <label htmlFor="address" className="required">
                      Address:
                    </label>{" "}
                    <input
                      id="address"
                      type="text"
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      required="required"
                      placeholder="Enter your address"
                      className="form-control square"
                    />
                    <span className='text-danger'>{errorList.address}</span>
                  </div>{" "}
                  <div className="form-group">
                    <div className="custome-checkbox">
                      <input type="checkbox" name="is_default" value={1} id="is_default" onClick={(e) => setDefault(!defaultValue)} className="form-check-input" />{" "}
                      <label htmlFor="is_default" className="form-check-label">
                        <span>Use this address as default.</span>
                      </label>
                    </div>
                  </div>{" "}
                  <div className="col-md-12">
                    <button type="submit" className="btn btn-fill-out submit">
                      Add a new address
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAddress;