import React, { useEffect, useState } from 'react'
import Footer from '../pages/Footer'
import MobileMenu from '../pages/mobile_menu'
import TopHeader from '../pages/topHeader'
import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom';
import axios from 'axios';

const Layout = ({ children }) => {

  const [footerBanner, setFooterBanner] = useState([]);
  const [socialMedia, setSocialMedia] = useState([]);
  const [footerData, setFooterData] = useState([]);
  const [footerAdvertisements, setFooterAdvertisement] = useState([]);
  useEffect(() => {
    axios.get("footer-management")
      .then(resp => {
        setFooterData(resp.data.footerManagement);
      });

       if(window.location.pathname === '/'){
        axios.get("footer-banner")
          .then(resp => {
            setFooterBanner(resp.data.banner[0]);
          });
        }

    axios.get("social-media")
      .then(resp => {
        setSocialMedia(resp.data.socialMedia);
      });

    axios.get("footer-advertisement")
      .then(resp => {
        setFooterAdvertisement(resp.data.advertisement);
      });

  }, []);

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    async function callApi() {
      const apiResult = await axios.get("get-categories");
      setCategories(apiResult.data.categories);
      // console.log(apiResult.data.categories[0]);
    }
    callApi();
  }, []);

  const [user, setUser] = useState([])
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);
  // console.log('layout');
    return (
        <div>
            {socialMedia && footerData && (
            <MobileMenu socialMedia={socialMedia} categories={categories} footerData={footerData} />
            )}

            <div className=''>
                <div className='row justify-content-center'>
                    <div className='col-lg-11 col-xl-11 p-0 ps-lg-3 pe-lg-3 site_width'>

                        {categories && (
                        <TopHeader categories={categories} footerData={footerData} />
                        )} 

                        {children}

                        {footerAdvertisements && footerBanner && socialMedia && footerData && (
                        <Footer gg='gg life' footerBanner={footerBanner} socialMedia={socialMedia} footerData={footerData} footerAdvertisements={footerAdvertisements} />
                        )}
                    </div>
                </div>
            </div>
            <div className=''>
            <CookieConsent buttonText="Allow Cookie"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#ffffff", fontSize: "13px", backgroundColor: '#000000', border: '1px solid #f1f1f1', marginRight: '10rem' }}><span className='p-lg-5 ms-lg-4'>Your experience on this site will be improved by allowing cookies <Link to={'/cookie-policy'} >Cookie Policy</Link></span>
                </CookieConsent>
            </div>

            {/* <div class="js-cookie-consent cookie-consent cookie-consent-full-width page_speed_1505421405"><div class="cookie-consent-body page_speed_781726274"><span class="cookie-consent__message"> Your experience on this site will be improved by allowing cookies <a href="https://nest.botble.com/cookie-policy">Cookie Policy</a></span><button class="js-cookie-consent-agree cookie-consent__agree page_speed_2036905254"> Allow cookies </button></div></div> */}
        </div>
    )
}
export default React.memo(Layout);