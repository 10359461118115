import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";

import axios from "axios";
import Swal from "sweetalert2";
import Layout from "../layouts/Layout";



const Login = () => {
  const search = useLocation().search;
  const queryParam = new URLSearchParams(search);
  
  let [username, setUserName] = useState("");
  let [password, setPassword] = useState("");

  let navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // console.log("Full render")
    setLoading(true);
    if(queryParam.get('q') != null){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: queryParam.get('q'),
        // showConfirmButton: false,
        // timer: 2000
      })
    }
    
    setTimeout(() => {
        setLoading(false);
    }, 300);
  }, []);
  const loginSubmit = (e) => {
    var obj = { user_input: username, password: password };
    // console.log("username pass: " + obj.customer_email + obj.customer_password);
    console.log(obj);
    /* axios
      .post("/user", {
        firstName: "Fred",
        lastName: "Flintstone",
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      }); */
    axios
      .post("customer/login", obj)
      .then(function (resp) {
        console.log(resp.data);
        var data = resp.data;

        if (data.success == false) {
          setError(resp.data.message);
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: resp.data.message,
            showConfirmButton: false,
            timer: 2000
          })
        }
        // console.log(localStorage.getItem("user"));
        if (data.status) {
          var user = { token: data.token, customer_id: data.data.id, customer_name: data.data.customer_name, user: data.data };
          localStorage.setItem("user", JSON.stringify(user));
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successfully logged in',
            showConfirmButton: false,
            timer: 1500
          })
          navigate("/");
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log("error : " + err);
      });
    e.preventDefault();
  };

  return (
    <div>
        <Layout>
        {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
        <main className="main" id="main-section">
          <div className="page-header breadcrumb-wrap">
              <div className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
                <a href="/#" itemProp="item" title="Home">
                  Home
                  <meta itemProp="name" content="Home" />
                  <meta itemProp="position" content="1" />
                </a>
                <span></span> Login
                <meta itemProp="name" content="Login" />
                <meta itemProp="position" content="2" />
              </div>
          </div>
          <div className="page-content pt-150 pb-150">
              <div className="row">
                <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                  <div className="row">
                    <div className="col-lg-6 pr-30 d-none d-lg-block">
                      <img className="border-radius-15" src={"/assets/images/logo/Original_logo.png"} alt="" />
                    </div>
                    <div className="col-lg-6 col-md-8">
                      <div className="login_wrap widget-taber-content background-white">
                        <div className="padding_eight_all bg-white">
                          <div className="heading_s1">
                            <h1 className="mb-5">Login</h1>
                            <p className="mb-30">
                              Don&#039;t have an account? <Link to="/register">Create one</Link>
                            </p>
                          </div>
                          <span style={{ color: "red" }}>{error}</span>
                          <form
                            onSubmit={(e) => {
                              loginSubmit(e);
                            }}
                          >
                            <div className="form-group">
                              {/*   <input type="text" required name="text" placeholder="Username or Email*" /> */}
                              <input
                                type="text"
                                name="username"
                                id="username"
                                placeholder="Phone Or Email*"
                                required
                                value={username}
                                onChange={(e) => setUserName(e.target.value)}
                              />
                            </div>
                            {/*
                             */}{" "}
                            <div className="form-group">
                              {/*  <input type="password" required name="password"  placeholder="Your password*" /> */}
                              <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Password*"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                            <div className="login_footer form-group mb-50">
                              <div className="chek-form">
                                <div className="custome-checkbox">
                                  <input className="form-check-input" type="checkbox" name="remember" id="remember-checkbox" value="" />

                                  <label className="form-check-label" htmlFor="remember-checkbox">
                                    <span>Remember me</span>
                                  </label>
                                </div>
                              </div>
                              <Link className="text-muted" to="/forgot-password">
                                Forgot password?
                              </Link>
                            </div>
                            <div className="form-group">
                              <button type="submit" className="btn btn-heading btn-block hover-up">
                                Login
                              </button>
                              {/*  <div class="php-email-form">
                                <button type="submit">Login</button>
                              </div> */}
                            </div>
                            <div className="text-left"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </main>
        </Layout>
    </div>
  );
};

export default Login;
