import React from "react";
import ProductBox3 from "./productBox3";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import { CartProvider, useCart } from "react-use-cart";
import Swal from 'sweetalert2'

const FlashSell = () => {
  const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life - Flash Sell";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { addItem } = useCart();
  const [products, setProducts] = useState("");

  useEffect(() => {
    axios.get("get-products")
      .then(resp => {
          setProducts(resp.data.products);
      });
  }, []);

  return (
    <div>
      {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
      <Layout>

      <div className="row product-grid-4 ">
      {products &&
        products.map((product, index) => {
          return (
            // <div className="row product-grid-4 " key={product.id}>
              product.product_collection_id && (
                <div key={index} className="col-6 col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-lg-0 mb-md-5 mb-sm-5">
                <div
                  data-wow-delay="0.1s"
                  className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                  style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                >
                  <div className="product-img-action-wrap">
                    <div className="product-img product-img-zoom">
                      <Link to={"/product_details/" + product.id +'/'+product.product_slug}>
                        <img
                          src={product.product_image.medium}
                          alt="not found img"
                          className="default-img"
                        />
                      </Link>
                    </div>
                    {/* discount percentage */}
                    {product.product_discount>0 && (
                  <div className="product-badges product-badges-position product-badges-mrg">
                    <span className="hot">{product.product_discount}%</span>
                  </div>
                    )}
                  </div>
                  <div className="product-content-wrap">
                    <h2>
                      <Link to={"/product_details/" + product.id +'/'+product.product_slug}>{product.product_name}</Link>
                    </h2>
                    <div className="product-rate-cover">
                      <div className="product-rate d-inline-block">
                        <div className="product-rating" style={{ width: "80%" }}></div>
                      </div>
                      <span className="font-small ml-5 text-muted"> (3)</span>
                    </div>

                    <div className="product-card-bottom row">
                      <div className="col-12 col-md-6 text-center">
                        {product.product_discount > 0 ? (
                          <div className="product-price">
                            <span>৳{Math.ceil(product.final_product_price - ((product.final_product_price * product.product_discount) / 100))}</span>
                            <span className="old-price">৳{product.final_product_price}</span>
                          </div>
                        ) : (
                          <div className="product-price">
                            <span>৳{product.final_product_price}</span>
                            {/* <span className="old-price">৳{product.final_product_price}</span> */}
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-md-6 text-center">
                        <div
                          className="add-cart"
                          onClick={() =>
                            addItem({
                              id: product.id,
                              name: product.product_name,
                              price: (product.product_discount > 0 ? (Math.ceil(product.final_product_price - ((product.final_product_price * product.product_discount) / 100))) : (product.final_product_price)),
                              quantity: 1,
                              image: product.product_image.small,
                            })
                          }
                        >
                          <Link aria-label="Add To Cart" data-id="2" data-url="#" to="" className="action-btn add-to-cart-button add" onClick={() =>
                            Swal.fire({
                              position: 'top-end',
                              icon: 'success',
                              title: 'Product added to cart',
                              showConfirmButton: false,
                              timer: 800
                            })
                          }>
                            <i className="fi-rs-shopping-cart mr-5"></i>Add
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )
            // </div>
          );
        })}
    </div>

      </Layout>
    </div>
  );
};

export default FlashSell;
