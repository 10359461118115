import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';

const BlogView = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life - Blog";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const { id } = useParams();
  const [blog, setBlog] = useState();
  useEffect(() => {
    axios.get("blog/" + id)
      .then(resp => {
        console.log(resp.data);
        setBlog(resp.data.data);
      });
  }, []);

  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span>
            <Link to="/blogs" itemProp="item" title="Blogs">
              Blogs
              <meta itemProp="name" content="Blogs" />
              <meta itemProp="position" content="2" />
            </Link>
            <span></span> Blog
            <span></span>
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-9 pt-1 pb-4">
            {blog && (
              <div className="single-page">
                <div class="single-header style-2">
                  <div class="row">
                    <div class="col-lg-12 m-auto">
                      <h6 class="mb-10"></h6>
                      <h2 class="mb-10">{blog.title}</h2>
                      <div class="single-header-meta">
                        <div class="entry-meta meta-1 font-xs mt-15 mb-15">
                          {/* <span class="author-avatar">
                            <img src="data:image/png;base64,5CYII=" alt="System Admin" class="img-circle" />
                          </span> */}
                          <span class="post-by">By {blog.author}</span>
                          <span class="post-on has-dot">{blog.created_at.slice(0,10)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <center>
                <figure class="single-thumbnail">
                  <img src={axios.defaults.baseURL.slice(0, -4) + "admin/images/blogs/" + blog.image} alt={blog.title}/>
                </figure>
                </center>
                <div className="single-content">
                  <div className="row">
                    <div className="col-lg-12 m-auto">
                    {Parser(blog.description)}
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>

      </Layout>
    </div>
  );
};

export default BlogView;
