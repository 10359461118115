import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "none", background: "black" }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "none", background: "black" }} onClick={onClick} />;
}

export default class NewsFlash extends Component {
  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 2000,
      centerMode: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      beforeChange: function (currentSlide, nextSlide) {
        /*  console.log("before change", currentSlide, nextSlide); */
      },
      afterChange: function (currentSlide) {
        /* console.log("after change", currentSlide); */
      },
    };
    return (
      <div className="text-center">
        <ul>
          <Slider {...settings}>
            <div>
              <div style={{ marginBottom: "60px" }}>
                <li>
                  <i className="fi-rs-bell d-inline-block mr-5"></i>
                  <span className="d-inline-block">
                    <b className="text-success"> Trendy 25</b>, save up 35% off today&nbsp;
                  </span>
                  <Link className="active d-inline-block" to="/products">
                    Shop now
                  </Link>
                </li>
              </div>
            </div>

            <div>
              <div style={{ marginBottom: "60px" }}>
                <li>
                  <i className="fi-rs-asterisk d-inline-block mr-5"></i>
                  <span className="d-inline-block">
                    <b className="text-danger">Supper Value Deals</b> - Save more with coupons
                  </span>
                </li>
              </div>
            </div>

            <div>
              <div style={{ marginBottom: "60px" }}>
                <li>
                  <i className="fi-rs-angle-double-right d-inline-block mr-5"></i>
                  <span className="d-inline-block">Get great devices up to 50% off&nbsp;</span>
                  <Link className="active d-inline-block" to="/products">
                    View details
                  </Link>
                </li>
              </div>
            </div>
          </Slider>
        </ul>
      </div>
    );
  }
}
