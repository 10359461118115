import React from "react";
// import { IncreaseQuantity, DecreaseQuantity, DeleteCart, GetNumberCart } from "../Redux/actions";
import { connect } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import { CartProvider, useCart } from "react-use-cart";
import Swal from "sweetalert2";

const CartPopup = (prop) => {
  
  const {
    isEmpty,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
  } = useCart();

  let totalPrice = 0;

    items.forEach(function (item) {
        totalPrice += item.quantity * item.price;
    });

  return (
    <div>
      <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
        {items.map((item, key) => {
          // console.log("list total : " + key);
          return (
            <div key={key}>
              <ul key={key}>
                <li key={key}>
                  <div className="shopping-cart-img">
                    <a href="#">
                      <img alt="Chobani Complete Vanilla Greek" src={item.image} />
                    </a>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link to={"/product_details/" + item.id}>{item.name} </Link>
                    </h4>
                    <h3>
                    {" "}
                      <span className="d-inline-block">{item.price*item.quantity} ৳</span> <small></small>
                    </h3>
                    <p className="mb-0"></p>
                  </div>
                  <div className="shopping-cart-delete" onClick={() => removeItem(item.id)}>
                    <a href="#" data-url="https://nest.botble.com/cart/remove/75e8e3e8e64a61cd81ff260ad89b011b" className="remove-cart-item" onClick={() =>
                                Swal.fire({
                                  position: 'top-end',
                                  icon: 'warning',
                                  title: 'Product removed form the cart',
                                  showConfirmButton: false,
                                  timer: 800
                                })
                              }>
                      <i className="fi-rs-cross-small"></i>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          );
        })}
        <div className="shopping-cart-footer">
          <div className="shopping-cart-total">
            <h5>
              <strong className="d-inline-block">Sub Total:</strong>
                    <span>{totalPrice} ৳</span>
            </h5>
            <div className="clearfix"></div>
            <h5>
              <strong className="d-inline-block">Tax:</strong> <span>-- ৳</span>
            </h5>
            <div className="clearfix"></div>
            <h4>
            </h4>
          </div>
          <div className="shopping-cart-button">
            <Link className="btn" to="/cart">View cart</Link>
            <Link to="/checkout">Checkout</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state) => {
//   // console.log('state: '+state._todoProduct)
//   return {
//     items: state._todoProduct,
//   };
// };

// export default connect(mapStateToProps, { IncreaseQuantity, DecreaseQuantity, DeleteCart })(CartPopup);
export default CartPopup;
