import React, { Component } from 'react';
import TopHeader from '../topHeader';
/* import ProductBox from '../productBox'; */
import Dashboard from './dashboard';
import Footer from '../Footer';
class BecomeVendor extends Component {
    state = {  } 
    componentDidMount() {
        document.title = " Become Vendor"; 
     }
    render() { 
        return (
            <div>
            <div>
                <TopHeader></TopHeader>
            </div>
            <div className="page-header breadcrumb-wrap">
        <div className="container">
            <div className="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
                <a href="https://nest.botble.com" itemprop="item" title="Home">
Home
<meta itemprop="name" content="Home" />
<meta itemprop="position" content="1" />
</a>
                <span></span>    Become Vendor
                <meta itemprop="name" content="Account information" />
                <meta itemprop="position" content="2" />
            </div>
        </div>
    </div>
    <div style={{display:'flex',padding:'200px 40px 200px 200px'}}>
                <Dashboard></Dashboard>
                <div style={{width:'70%'}}>

                <div className="tab-content account dashboard-content pl-50">
                                    <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                                        <div className="card">
                                            <form method="POST" action="https://nest.botble.com/vendor/become-vendor" accept-charset="UTF-8" className="ps-form--account-setting"><input name="_token" type="hidden" value="3YJhudxqVbQtddq2gBdpTDkqPnB5O158QEDwVeB2"/>
                                                <div className="card-header">
                                                    <h3 className="mb-0">Become Vendor</h3>
                                                </div>
                                                <div className="ps-form__content">
                                                    <input type="hidden" name="is_vendor" value="1"/>
                                                    <div className="form-group">
                                                        <label for="shop-name" className="required">Shop Name</label>
                                                        <input className="form-control" name="shop_name" id="shop-name" type="text" value="" placeholder="Shop Name"/>
                                                    </div>
                                                    <div className="form-group shop-url-wrapper">
                                                        <label for="shop-url" className="required float-left">Shop URL</label>
                                                        <span className="d-inline-block float-right shop-url-status"></span>
                                                        <input className="form-control" name="shop_url" id="shop-url" type="text" value="" placeholder="Shop URL" data-url="https://nest.botble.com/ajax/stores/check-store-url"/>
                                                        <span className="d-inline-block"><small data-base-url="https://nest.botble.com/stores">https://nest.botble.com/stores</small></span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="shop-phone" className="required">Phone Number</label>
                                                        <input className="form-control" name="shop_phone" id="shop-phone" type="text" value="" placeholder="Shop phone"/>
                                                    </div>
                                                    <div className="form-group text-center">
                                                        <div className="form-group submit">
                                                            <button className="submit submit-auto-width">Register</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                          
                
                </div>
            </div>
            <div>
                <Footer></Footer>
            </div>
        </div>
        );
    }
}
 
export default BecomeVendor;