import React from "react";
import axios from "axios";
import { Link,useNavigate } from "react-router-dom";
import ProductBox3 from "./productBox3";
import Swal from "sweetalert2";
import Layout from "../layouts/Layout";
import { useState } from "react";
import { useEffect } from "react";
import { CartProvider, useCart } from "react-use-cart";


const Wishlist = () => {
  //  console.log(items)
  let navigate = useNavigate();
  const { addItem } = useCart();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

    // useEffect(() => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 500);
    // }, []);

  const [wishlists, setWishlists] = useState();
  var customer_id;
  if (localStorage.getItem("user")) {
    var obj = JSON.parse(localStorage.getItem("user"));
    customer_id = obj.customer_id;
    // console.log(obj);
  }
  useEffect(() => {
    setLoading(true);
    if(customer_id){
      axios.get("get-wishlist/" + customer_id)
      .then(resp => {
        if(resp.data.success==true){
          setLoading(false);
          setWishlists(resp.data.data);
          // if(wishlists.length<=0){
          // }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, [reload,customer_id]);

  // console.log(wishlists);
  const removeFromWishlist = (wID) => {
    setLoading(true);
    if(wID){
      // setWishlists([]);
      axios.get("delete-wishlist/" + wID)
      .then(resp => {
        if(resp.data.success==true){
          setLoading(false);
          setReload(true);
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'Product removed from wishlist',
            showConfirmButton: false,
            timer: 800
          })
          navigate('/wish-list');
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }

  return (
    <div>
      {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
        <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div itemScope="itemscope" itemType="" className="breadcrumb">
              <Link to="/" itemProp="item" title="Home">
                Home
                <meta itemProp="name" content="Home" /> <meta itemProp="position" content="1" />
              </Link>{" "}
              <span></span>
              Wishlist
              <meta itemProp="name" content="Shopping Cart" /> <meta itemProp="position" content="2" />
            </div>
          </div>
        </div>
          <div className="mb-80 mt-50 section--shopping-cart">
            <div className="row">
              <div className="col-lg-8 mb-40">
                <h1 className="heading-2 mb-10">Your Wishlist</h1>{" "}
                <div className="d-flex justify-content-between">
                  <h6 className="text-body">
                    There are <span className="text-brand">{wishlists && wishlists.length}</span> products in your wishlist
                  </h6>
                </div>
              </div>
            </div>{" "}
            {/* <form method="post" action="#"> */}
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive shopping-summery">
                  <table className="table table-wishlist table--cart">
                    <thead>
                      <tr className="main-heading">
                        <th scope="col" colSpan="2" className="start pl-30">
                          Product
                        </th>
                        <th scope="col">Price</th>
                        <th scope="col" className="pl-30">Stock</th>
                        <th scope="col" className="pl-30">Action</th> 
                        <th scope="col" className="end pl-30">Remove&nbsp;&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                    {wishlists && wishlists.map((wishlist, key) => {
                        return (
                          <tr key={key} className="pt-30">
                            <td className="image product-thumbnail">
                              <img src={wishlist.products.product_image.small} alt="Product Image" />
                            </td>
                            <td className="product-des product-name" style={{paddingLeft:'1rem'}}>
                              <h6 className="mb-5">
                                <Link to={"/product_details/"+wishlist.products.id} className="product-name mb-10 text-heading">
                                  {wishlist.products.product_name}
                                </Link>
                              </h6>
                              {/* <p className="mb-0"> <small>(Weight: 4KG)</small></p> */}
                            </td>
                            <td data-title="Price" className="price">
                              <h5 className="text-body"> ৳{wishlist.products.product_price} </h5>{" "}
                              {/* <small>
                                  <del>$90.00</del>
                                </small> */}
                            </td>
                            <td data-title="Stock" className="pl-30">
                              <h5 className={Number(wishlist.products.stock)<1 ? "text-body ps-1" : "text-body ps-1 text-success"}> {wishlist.products.stock} </h5>{" "}
                              {/* <small>
                                  <del>$90.00</del>
                                </small> */}
                            </td>
                            <td data-title="Action" className="" style={{ whiteSpace: 'nowrap' }}>
                            <div
                          className="add-cart"
                          onClick={() =>
                            addItem({
                              id: wishlist.products.id,
                              name: wishlist.products.product_name,
                              price: wishlist.products.product_price,
                              image: wishlist.products.product_image.small,
                            })
                          }
                        >
                          <Link aria-label="Add To Cart" data-id="2" data-url="#" to="" className="btn add-to-cart-button add" onClick={() =>
                            Swal.fire({
                              position: 'top-end',
                              icon: 'success',
                              title: 'Product added to cart',
                              showConfirmButton: false,
                              timer: 800
                            })
                          }>
                            <i className="fi-rs-shopping-cart mr-5"></i>Add To Cart
                          </Link>
                        </div>
                              {" "}
                            </td>
                            <td data-title="Remove" className="action text-center">
                              <a href="#" data-url="" className="text-body remove-cart-button" onClick={() =>
                                removeFromWishlist(wishlist.id)
                              }>
                                <i className="fi-rs-trash"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>{" "}
                <div className="divider-2 mb-30"></div>{" "}
                <div className="cart-action d-flex justify-content-between">
                  <Link to={"/"} className="btn ">
                    <i className="fi-rs-arrow-left mr-10"></i>Continue Shopping
                  </Link>
                </div>{" "}
              </div>
              
            </div>
            {/* </form> */}
          </div>

          {/* related product thakbe */}
          <div className="row mt-60" id="related-products">
            <div className="col-12">
              <h3 className="section-title style-1 mb-30">You may also like</h3>
            </div>
          </div>
        <div>
          {" "}
          <ProductBox3></ProductBox3>{" "}
        </div>
        </Layout>
    </div>
  );
}

export default Wishlist;
