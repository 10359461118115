import axios from "axios";
import React, { useEffect, useState } from "react";
// import Carousel from "react-bootstrap/Carousel";
import { Carousel } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../assests/Css/banner.css'

const BannerSlider  = () => {

  const [loading, setLoading] = useState(false);
  useEffect(() => {
        setLoading(true);
    }, []);
  
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    axios.get("homepage-banner")
      .then(resp => {
        setLoading(false);
        setBanners(resp.data.banner);
      });
  }, []);
//  console.log(banners);
    return (
      <>
      {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            
      <Carousel className="pt-lg-4">
      {banners &&
        banners.map((banner) => {
          return (
            
            banner.link && banner.link.length>1 ? (
            <Carousel.Item key={banner.id} className='p-2'>
              
              <Link to={'/flash-sell'}>
                  <img className="d-block top-slider-img banner_image" src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/banner_images/" + banner.banner_image} alt={banner.title} />
              <Carousel.Caption>
                <div className="top-left">
                  <div className="banner_text_div">
                    {banner.title && (
                    <h1 className="display-22 mb-40 banner_text">
                      {banner.title}
                    </h1>
                    )}
                    {banner.sub_title && (
                    <p className="mb-65 banner_text banner_sub_text">{banner.sub_title}</p>
                    )}
                 
                  </div>

                </div>
              </Carousel.Caption>
            </Link>
            </Carousel.Item>
              ): (
                <Carousel.Item key={banner.id} className='p-2'>
                <img className="d-block top-slider-img banner_image" src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/banner_images/" + banner.banner_image} alt={banner.title} />
            <Carousel.Caption>
              <div className="top-left">
                <div className="banner_text_div">
                  <h1 className="display-22 mb-40 banner_text">
                    {banner.title}
                  </h1>
                  <p className="mb-65 banner_text banner_sub_text">{banner.sub_title}</p>
               
                </div>

              </div>
            </Carousel.Caption>
          </Carousel.Item>
              )
            
          );
        })}

    </Carousel>
      </>
    );
  
}

export default BannerSlider;
