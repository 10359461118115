import React from "react";
import TopHeader from "../topHeader";
import Dashboard from "./dashboard";
import Footer from "../Footer";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

const Profile = () => {
  var customer_id = null;
  if (localStorage.getItem("user")) {
    var obj = JSON.parse(localStorage.getItem("user"));
    customer_id = obj.customer_id;
  }
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    axios
      .get("https://ultimateapi.hostprohub.com/api/get-edit-customer/" + customer_id)
      .then(function (resp) {
        console.log("profile response: " + resp.data);
        setUserData(resp.data);
      })
      .catch((err) => {
        console.log("error : " + err);
      });
  }, [customer_id]);

  // console.log(userData);

  return (
    <div>
      <div>
        <TopHeader></TopHeader>
      </div>
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
            <a href="/" itemprop="item" title="Home">
              Home
              <meta itemprop="name" content="Home" />
              <meta itemprop="position" content="1" />
            </a>
            <span></span> Profile
            <meta itemprop="name" content="Account information" />
            <meta itemprop="position" content="2" />
          </div>
        </div>
      </div>
      <div
        className="page-content pt-30 pb-150"
        style={
          {
            /*  display: "flex",
          padding: "200px 40px 200px 200px", */
          }
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <div className="col-md-3">
                  <Dashboard></Dashboard>
                </div>

                <div class="col-md-9">
                  <div className="tab-content account dashboard-content pl-50">
                    <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                      <div className="card">
                        <div className="card">
                          <div className="card-header">
                            <h5>Account Details</h5>
                          </div>
                          <div className="card-body">
                            <form method="POST" action="https://nest.botble.com/customer/edit-account" accept-charset="UTF-8">
                              <input name="_token" type="hidden" value="3YJhudxqVbQtddq2gBdpTDkqPnB5O158QEDwVeB2" />
                              <div className="row">
                                <div className="form-group col-md-12 ">
                                  <label className="required" for="name">
                                    Full Name:
                                  </label>
                                  <input required className="form-control square" name="name" type="text" id="name" value={userData.customer_name} />
                                </div>
                                <div className="form-group col-md-12 ">
                                  <label for="date_of_birth">Date of birth:</label>
                                  <input
                                    id="date_of_birth"
                                    type="text"
                                    className="form-control square"
                                    name="dob"
                                    placeholder="Y-m-d"
                                    value={userData.customer_dob}
                                  />
                                </div>
                                <div className="form-group col-md-12">
                                  <label for="email">Email:</label>
                                  <input
                                    id="email"
                                    type="text"
                                    className="form-control"
                                    disabled="disabled"
                                    value={userData.customer_email}
                                    name="email"
                                  />
                                </div>
                                <div className="form-group col-md-12 ">
                                  <label for="phone">Phone:</label>
                                  <input
                                    type="text"
                                    className="form-control square"
                                    name="phone"
                                    id="phone"
                                    placeholder="Phone"
                                    value={userData.customer_contact}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <button type="submit" className="btn btn-fill-out submit">
                                    Update
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default Profile;
