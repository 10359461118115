import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import { Provider } from "react-redux";
// import store from "./Redux/stores";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { CartProvider } from 'react-use-cart';

axios.defaults.baseURL="https://ultimateapi.hostprohub.com/api/";
// axios.defaults.baseURL="http://127.0.0.1:8000/api/";
// axios.defaults.baseURL="http://192.168.100.99:8000/api/";
axios.defaults.headers.common["Accept"] = 'application/json';
axios.defaults.headers.common["retry-after"] = 3600;
var token = null;
if (localStorage.getItem("user")) {
  var obj = JSON.parse(localStorage.getItem("user"));
  // console.log(obj);
  token = obj.token;
  axios.defaults.headers.common["Authorization"] = 'Bearer '+token;
  // axios.defaults.headers.common["Authorization"] = token;
  // axios.defaults.headers.common["bearerToken"] = token;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <Provider store={store}>
    <CartProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
    </CartProvider>
  // </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
