import React, { useState, useEffect } from 'react';
import TopHeader from './topHeader';
import ProductBox3 from './productBox3';
import Footer from './Footer';
import MobileMenu from './mobile_menu';
import { Link } from 'react-router-dom';
import Layout from '../layouts/Layout';
import Filter from './filter';

const AllProducts = () => {
  //All products page :(
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState('');

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    document.title = "Ultimate Organic Life - Products";
  }, []);

  const filterInfo = (index) => { // the callback. Use a better name
    console.log(index);
  };
  // let [sortBy, setSortBy] = useState("");
  // let [sortType, setSortType] = useState("");
  // console.log('sort_by: '+sortBy+' sort_type: '+sortType);
  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span> Products
            <span></span>
          </div>
        </div>
        <br />
        {/* <div className='row d-flex flex-row-reverse mb-4'>
          <div className='col-4'>
            <div className='row justify-content-end'>
            <div className='col-4'> <select class="form-control"
              name="city">
              <option value={null}><i className="fi-rs-apps-sort">Show</i></option>
              <option value={'inside_dhaka'}>Inside Dhaka</option>
              <option value={'outside_dhaka'}>Outside Dhaka</option>
            </select>
            </div>
            <div className='col-5'>
              <select class="form-control"
                name="city"
                value={sortBy} onChange={(e) => {setSortBy(e.target.value); setSortType(e.target.childNodes[e.target.selectedIndex].getAttribute('type'));} }>
                <option value={null}><i className="fi-rs-apps-sort">Sort by</i></option>
                <option value={'id'} type={'desc'}>Newest</option>
                <option value={'id'} type={'asc'}>Oldest</option>
                <option value={'product_price'} type={'asc'}>Price:low to high</option>
                <option value={'product_price'} type={'desc'}>Price:high to low</option>
              </select>
            </div>
            </div>
          </div>
        </div> */}
        {/* <Filter filterInfo={filterInfo} /> */}
        <ProductBox3 productType="get-products"/>
        <br />
      </Layout>

    </div>
  );
}

export default AllProducts;