import React from "react";

const Payment = () => {
  return (
    <div>
      <div class="position-relative">
        <div class="payment-info-loading" style={{ display: "none" }}>
          <div class="payment-info-loading-content">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
        </div>
        <h5 class="checkout-payment-title">Payment method</h5>
        <input type="hidden" name="amount" value="35.0658" />
        <input type="hidden" name="currency" value="USD" />
        <ul class="list-group list_payment_method">
          <li class="list-group-item">
            <input
              class="magic-radio js_payment_method collapsed"
              type="radio"
              name="payment_method"
              id="payment_stripe"
              value="stripe"
              data-bs-toggle="collapse"
              data-bs-target=".payment_stripe_wrap"
              data-toggle="collapse"
              data-target=".payment_stripe_wrap"
              data-parent=".list_payment_method"
              aria-expanded="false"
            />
            <label for="payment_stripe" class="text-start">
              Pay online via Stripe
            </label>
            <div class="payment_stripe_wrap payment_collapse_wrap collapse" style={{ padding: "15px" }}>
              <p>Payment with Stripe</p>
              <div class="card-checkout" style={{ maxWidth: "350px" }}>
                <div class="form-group mt-3 mb-3">
                  <div class="stripe-card-wrapper" data-jp-card-initialized="true">
                    <div class="jp-card-container" style={{ transform: "scale(1)" }}>
                      <div class="jp-card">
                        <div class="jp-card-front">
                          <div class="jp-card-logo jp-card-elo">
                            <div class="e">e</div>
                            <div class="l">l</div>
                            <div class="o">o</div>
                          </div>
                          <div class="jp-card-logo jp-card-visa">Visa</div>
                          <div class="jp-card-logo jp-card-visaelectron">
                            Visa<div class="elec">Electron</div>
                          </div>
                          <div class="jp-card-logo jp-card-mastercard">Mastercard</div>
                          <div class="jp-card-logo jp-card-maestro">Maestro</div>
                          <div class="jp-card-logo jp-card-amex"></div>
                          <div class="jp-card-logo jp-card-discover">discover</div>
                          <div class="jp-card-logo jp-card-unionpay">UnionPay</div>
                          <div class="jp-card-logo jp-card-dinersclub"></div>
                          <div class="jp-card-logo jp-card-hipercard">Hipercard</div>
                          <div class="jp-card-logo jp-card-troy">troy</div>
                          <div class="jp-card-logo jp-card-dankort">
                            <div class="dk">
                              <div class="d"></div>
                              <div class="k"></div>
                            </div>
                          </div>
                          <div class="jp-card-logo jp-card-jcb">
                            <div class="j">J</div>
                            <div class="c">C</div>
                            <div class="b">B</div>
                          </div>
                          <div class="jp-card-lower">
                            <div class="jp-card-shiny"></div>
                            <div class="jp-card-cvc jp-card-display jp-card-invalid">•••</div>
                            <div class="jp-card-number jp-card-display jp-card-invalid">•••• •••• •••• ••••</div>
                            <div class="jp-card-name jp-card-display jp-card-invalid">Full Name</div>
                            <div class="jp-card-expiry jp-card-display jp-card-invalid" data-before="mm/yyyy" data-after="valid date">
                              ••/••
                            </div>
                          </div>
                        </div>
                        <div class="jp-card-back">
                          <div class="jp-card-bar"></div>
                          <div class="jp-card-cvc jp-card-display jp-card-invalid">•••</div>
                          <div class="jp-card-shiny"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-3 ">
                  <div class="row">
                    <div class="col-sm-8">
                      <input
                        placeholder="Card number"
                        class="form-control jp-card-invalid"
                        type="text"
                        id="stripe-number"
                        data-stripe="number"
                        autocomplete="off"
                      />
                    </div>
                    <div class="col-sm-4">
                      <input
                        placeholder="MM/YY"
                        class="form-control jp-card-invalid"
                        type="text"
                        id="stripe-exp"
                        data-stripe="exp"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group mb-3 ">
                  <div class="row">
                    <div class="col-sm-8">
                      <input
                        placeholder="Full name"
                        class="form-control jp-card-invalid"
                        id="stripe-name"
                        type="text"
                        data-stripe="name"
                        autocomplete="off"
                      />
                    </div>
                    <div class="col-sm-4">
                      <input
                        placeholder="CVC"
                        class="form-control jp-card-invalid"
                        type="text"
                        id="stripe-cvc"
                        data-stripe="cvc"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="payment-stripe-key" data-value="pk_test_7XJekDehXaxssmHNfkQMG4aG"></div>
            </div>
          </li>

          <li class="list-group-item">
            <input
              class="magic-radio js_payment_method"
              type="radio"
              name="payment_method"
              id="payment_paypal"
              value="paypal"
              data-bs-toggle="collapse"
              data-bs-target=".payment_paypal_wrap"
              data-toggle="collapse"
              data-target=".payment_paypal_wrap"
              data-parent=".list_payment_method"
            />
            <label for="payment_paypal" class="text-start">
              Pay online via PayPal
            </label>
            <div class="payment_paypal_wrap payment_collapse_wrap" style={{ padding: "15px 0" }}>
              <p>Payment with PayPal</p>
            </div>
          </li>
          <li class="list-group-item">
            <input
              class="magic-radio js_payment_method"
              type="radio"
              name="payment_method"
              id="payment_razorpay"
              value="razorpay"
              data-bs-toggle="collapse"
              data-bs-target=".payment_razorpay_wrap"
              data-toggle="collapse"
              data-target=".payment_razorpay_wrap"
              data-parent=".list_payment_method"
            />
            <label for="payment_razorpay">Online payment via Razorpay</label>
            <div class="payment_razorpay_wrap payment_collapse_wrap">
              <p>Payment with Razorpay</p>
            </div>
            <input type="hidden" id="rzp_order_id" value="order_KGpDODaKoCq9rc" />
          </li>

          <li class="list-group-item">
            <input
              class="magic-radio js_payment_method"
              type="radio"
              name="payment_method"
              id="payment_paystack"
              value="paystack"
              data-bs-toggle="collapse"
              data-bs-target=".payment_paystack_wrap"
              data-toggle="collapse"
              data-target=".payment_paystack_wrap"
              data-parent=".list_payment_method"
            />
            <label for="payment_paystack">Online payment via Paystack</label>
            <div class="payment_paystack_wrap payment_collapse_wrap">
              <p>Payment with Paystack</p>
            </div>
          </li>
          <li class="list-group-item">
            <input
              class="magic-radio js_payment_method"
              type="radio"
              name="payment_method"
              id="payment_mollie"
              value="mollie"
              data-bs-toggle="collapse"
              data-bs-target=".payment_mollie_wrap"
              data-toggle="collapse"
              data-target=".payment_mollie_wrap"
              data-parent=".list_payment_method"
            />
            <label for="payment_mollie">Online payment via Mollie</label>
            <div class="payment_mollie_wrap payment_collapse_wrap">
              <p>Payment with Mollie</p>
            </div>
          </li>
          <li class="list-group-item">
            <input
              class="magic-radio js_payment_method"
              type="radio"
              name="payment_method"
              id="payment_cod"
              value="cod"
              data-bs-toggle="collapse"
              data-bs-target=".payment_cod_wrap"
              data-parent=".list_payment_method"
            />
            <label for="payment_cod" class="text-start">
              Cash on delivery (COD)
            </label>
            <div class="payment_cod_wrap payment_collapse_wrap" style={{ padding: "15px 0" }}>
              Please pay money directly to the postman, if you choose cash on delivery method (COD).
            </div>
          </li>
          <li class="list-group-item">
            <input
              class="magic-radio js_payment_method"
              type="radio"
              name="payment_method"
              id="payment_bank_transfer"
              value="bank_transfer"
              data-bs-toggle="collapse"
              data-bs-target=".payment_bank_transfer_wrap"
              data-parent=".list_payment_method"
            />
            <label for="payment_bank_transfer" class="text-start">
              Bank transfer
            </label>
            <div class="payment_bank_transfer_wrap payment_collapse_wrap" style={{ padding: "15px 0" }}>
              Please send money to our bank account: ACB - 1990 404 19.
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Payment;
