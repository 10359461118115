import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import '../assests/Css/tracking.css'

const TrackOrder = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life - Order Tracking";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [trackingInfos, setTrackingInfos] = useState([]);
  const [orderNumber, setOrderNumber] = useState();

  useEffect(() => {
    axios.get("order-track?order_number=" + orderNumber)
      .then(resp => {
        console.log(resp.data);
        setTrackingInfos(resp.data.data);
      });
  }, [orderNumber]);

  // const track = (orderNo) => {
  //   // console.log(orderNo + "track theke");
  //     axios.get("order-track?order_number=" + orderNo)
  //     .then(resp => {
  //       console.log(resp.data);
  //       setTrackingInfo(resp.data.data);
  //     });
  // }

  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span> Track Order
            <span></span>
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 pt-1 pb-4 ">
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Type Your Order Number"
                aria-label="Type Your Order Number"
                aria-describedby="basic-addon2"
                value={orderNumber}
                onChange={(e) => { setOrderNumber(e.target.value); }}
              />
              <Button variant="outline-secondary" id="button-addon2"
              // onClick={track(orderNumber)}
              >
                Track Order
              </Button>
            </InputGroup>
          </div>
          <div className="col-md-8 col-lg-8">
            {/* <hr /> */}
            {!trackingInfos[0] && orderNumber && (
              <div className="text-warning text-center">
                No Order Found !
              </div>
            )}
            <div id="tracking-pre"></div>
            <div id="tracking">
              
            <div className="tracking-list">
              {trackingInfos &&
                [...trackingInfos].reverse().map((trackingInfo, index) => {
                  return (
                     <>
                     
                     <div className="tracking-item">
                        <div className="tracking-icon status-sponsored">
                          <svg className="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" viewBox="0 0 512 512" data-fa-i2svg="">
                            <path fill={index==0 ? "rgb(43, 162, 188)" : "currentColor"} d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                          </svg>
                        </div>
                        <div className="tracking-date">{trackingInfo.created_at.slice(0, 10)}<span>{trackingInfo.created_at.slice(11, 16)}</span></div>
                        <div className="tracking-content">{trackingInfo.properties.attributes && (trackingInfo.properties.attributes.status.charAt(0).toUpperCase() + trackingInfo.properties.attributes.status.slice(1))}
                          <span>{trackingInfo.description.charAt(0).toUpperCase() + trackingInfo.description.slice(1)}</span>
                        </div>
                      </div>
                     {(trackingInfos.length-1)==index && (
                      <div className="tracking-item">
                        <div className="tracking-icon status-sponsored">
                          <svg className="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" viewBox="0 0 512 512" data-fa-i2svg="">
                            <path fill={index==0 ? "rgb(43, 162, 188)" : "currentColor"} d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                          </svg>
                        </div>
                        <div className="tracking-date">{trackingInfo.created_at.slice(0, 10)}<span>{trackingInfo.created_at.slice(11, 16)}</span></div>
                        <div className="tracking-content">{trackingInfo.properties.old && (trackingInfo.properties.old.status.charAt(0).toUpperCase() + trackingInfo.properties.old.status.slice(1))}
                          <span>{trackingInfo.description.charAt(0).toUpperCase() + trackingInfo.description.slice(1)}</span>
                        </div>
                      </div>
                      )}
                      </>
                  );
                })}
                
                </div>
            </div>
          </div>
        </div>

      </Layout>
    </div>
  );
};

export default TrackOrder;
