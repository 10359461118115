import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { useCart } from "react-use-cart";
import Swal from 'sweetalert2'


const Checkout = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  const { emptyCart } = useCart();
  const {
    items,
  } = useCart();
  let customer_details = [];

  let totalPrice = 0;

  var customer_id = null;

  var customer_name = "";

  var customer_email = "";

  var customer_contact = "";
  // const [userData, setUserData] = useState([]);

  // let userData = [];
  var isLoggedIn = false;
  if (localStorage.getItem("user")) {
    var obj = JSON.parse(localStorage.getItem("user"));
    customer_id = obj.customer_id;
    customer_name = obj.customer_name;
    
      if(obj.customer_id){
        isLoggedIn = true;
      }
    // customer_contact = obj.customer_contact;
    // customer_email = obj.customer_email;
    // console.log(obj);
  }
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    if(customer_id){
    axios
      .get("get-edit-customer/" + customer_id)
      .then(function (resp) {
        setUserData(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, [customer_id]);
// console.log('user data');
// console.log(userData.customer_contact);
  if (customer_id) {
    customer_details = {
      customer_name: userData.customer_name,
      customer_email: userData.customer_email,
      customer_phone: userData.customer_contact,
      customer_address: "",
      customer_city: "",
      customer_zip: "",
    }
  }
  else{
  customer_details = {
    customer_name: customer_name,
    customer_email: customer_email,
    customer_phone: customer_contact,
    customer_address: "",
    customer_city: "",
    customer_zip: "",
  }
  }


  const [errorList, setError] = useState({
    name: '',
    email: '',
    contact: '',
  });
  let navigate = useNavigate();
  let [username, setUsername] = useState("");
  let [email, setEmail] = useState("");
  let [contact, setContact] = useState("");
  let [city, setCity] = useState(null);
  let [area, setArea] = useState("");
  let [zip, setZip] = useState("");
  let [address, setAddress] = useState("");
  let [orderNote, setOrderNote] = useState("");
  let [paymentMethod, setPaymentMethod] = useState("");
  let [shipingCost, setShipingCost] = useState("0.00");
  let [shipingMethod, setShipingMethod] = useState(false);
  let [vat, setVat] = useState("0.00");
  let [discount, setDiscount] = useState("0.00");
  let [coupon, setCoupon] = useState("");
  let [couponID, setCouponID] = useState(null);

  const { pId } = useParams();
  const { qty } = useParams();
  // const [type, setType] = useState("text");
  /* const [selected, setSelected] = useState("yes"); */
  const [product, setProduct] = useState([]);

  useEffect(() => {
    async function callApi() {
      const apiResult = await axios.get("get-product/" + pId);
      // console.log(apiResult.data.product);
      setProduct(apiResult.data.product);
    }
    callApi();
  }, [pId]);
  // console.log(product);
  const [shippingCharges, setShippingCharges] = useState([]);
  const [insideShiCharge, setInsideShiCharge] = useState("");
  const [outsideShiCharge, setOutsideShiCharge] = useState("");

  useEffect(() => {
    async function callApi() {
      const apiResult = await axios.get("get-shipping-charges");
      setShippingCharges(apiResult.data.shipping_charges);
      setInsideShiCharge(apiResult.data.shipping_charges[0].price);
      setOutsideShiCharge(apiResult.data.shipping_charges[1].price);
    }
    callApi();
  }, []);

  const [shippingZones, setShippingZones] = useState([]);

  useEffect(() => {
    axios.get("get-shipping-zones")
      .then(resp => {
        setShippingZones(resp.data.shipping_zones);
      }).catch(err => {
        console.log(err);
      });
  }, []);

  // console.log(paymentMethod);

  // console.log(product);

  var products = [];
  if (!pId) {
    items.forEach(function (item) {
      // console.log("listcart theke id"+item.id);
      products.push({
        id: item.id,
        product_name: item.name,
        product_price: item.price,
        qty: item.quantity,
        image: item.image
      });
    });
  }
  else {
    product.forEach(function (item) {
      // console.log("listcart theke id"+qty);
      products.push({
        id: item.id,
        product_name: item.product_name,
        product_price: Math.ceil(item.final_product_price - ((item.final_product_price * item.product_discount) / 100)),
        image: item.product_image.small,
        qty: qty,
      });
    });
  }

  if (!pId) {
    items.forEach(function (item) {
      totalPrice += item.quantity * item.price;
    });
  }
  else {
    product.forEach(function (item) {
      totalPrice += qty * Math.ceil(item.final_product_price - ((item.final_product_price * item.product_discount) / 100));
    });
  }

  const [addresses, setAddresses] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState([]);
// console.log(defaultAddress);
  useEffect(() => {
    if (customer_id) {
      setLoading(true);
      axios
        .get("get-customer-addresses/" + customer_id)
        .then(function (resp) {
          setLoading(false);
          // console.log(resp.data.data);
          if(resp.data.data){
            setAddresses(resp.data.data);
            setDefaultAddress(resp.data.data.find(address => address.is_default === 1));
            setUsername(resp.data.data.find(address => address.is_default === 1).name);
            setEmail(resp.data.data.find(address => address.is_default === 1).email);
            setContact(resp.data.data.find(address => address.is_default === 1).phone);
            setAddress(resp.data.data.find(address => address.is_default === 1).address);
            setZip(resp.data.data.find(address => address.is_default === 1).zip);
            setCity(resp.data.data.find(address => address.is_default === 1).shipping_state.id);
            setArea(resp.data.data.find(address => address.is_default === 1).shipping_area.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
        // console.log(city);
    }
  }, [customer_id]);
  const [changeAddress, setChangeAddress] = useState(false);
  const handleAddressChange = () => {
    setChangeAddress(true);
    // console.log("set-customer-default-address/" + defaultAddress.id +"/" + new_default_id);
  }
  const handleDefaultAddressChange = (new_default_id) => {
    // console.log("Default Address");
    axios.put("set-as-default-customer-address/" + customer_id + "/" + new_default_id)
      .then(function (resp) {
        console.log(resp.data);
        if (resp.data.success) {
          Swal.fire({
            customClass: {
              icon: 'mt-4'
            },
            position: 'center',
            icon: 'success',
            title: resp.data.message,
            // showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/checkout");
              window.location.reload(false);
            }
          });
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }
  const applyCoupon = () => {
    // console.log('coupon');
    if(coupon){
      setLoading(true);
      axios
        .post("coupon-apply", {coupon:coupon,sub_total:Number(totalPrice) + Number(shipingCost) + Number(vat)})
            .then(function (resp) {
              setLoading(false);
              // console.log(resp.data);
              if (resp.data.success==false) {
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: resp.data.message,
                  // showConfirmButton: false,
                  // timer: 2000
                })
              }
              else{
                setDiscount(resp.data.coupon_discount);
                setCouponID(resp.data.coupon_id);
              }
            })
            .catch((err) => {
              setLoading(false);
            });
    }
  };
  // console.log(defaultAddress);
  const checkOutSubmit = (event) => {
    
    if (shipingMethod && paymentMethod) {
      var shipping_details = {
        customer_name: username,
        customer_email: email,
        customer_phone: contact,
        customer_address: address,
        customer_city: city,
        customer_zip: zip,
        area_id: area,
      };

      if(!customer_details.customer_email){
        customer_details.customer_email="";
      }
      else if(!customer_details.customer_city){
        customer_details.customer_city="";
      }
      else if(!customer_details.customer_phone){
        customer_details.customer_phone="";
      }

      // if(city=='inside_dhaka'){
      //   shipping_details.customer_city=1;
      // }
      // else if(city=='outside_dhaka'){
      //   shipping_details.customer_city=2;
      // }

      // if(city=='inside_dhaka'){
      //   customer_details.customer_city=1;
      // }
      // else if(city=='outside_dhaka'){
      //   customer_details.customer_city=2;
      // }

      var order = {
        customer_id: customer_id,
        customer_details: customer_details,
        shipping_details: shipping_details,
        products: products,
        order_note: orderNote,
        payment_method: paymentMethod,
        shipping_cost: shipingCost,
        vat: vat,
        coupon_id: couponID
      };
      // console.log('order');
      // console.log(order);
      if (paymentMethod === "sslcommerz") {
        console.log(order);
        // console.log('sslcommerz');
        setLoading(true);
        axios.post('order', order)
          .then(resp => {
            // console.log('inside then');
            console.log(resp.data);
            if (resp.data.success) {
              emptyCart();
              window.location.replace(resp.data.data);
            }
            else {
              setLoading(false);
              setError(resp.data.message);
            }
            // window.location.replace(resp.gatewayPageUrl);
          })
          .catch(err => {
            setLoading(false);
            // console.log('inside catch');
            console.log(err.response);
            // setError(err.response.data.errors.message);
          });

      }
      else {
        axios
          .post("order", order)
          .then(function (resp) {
            // console.log(resp.data);
            var data = resp.data;
            if (resp.data.success) {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2200
              });
              if (!pId) {
                emptyCart();
              }
              if (customer_id) {
                navigate("/customer/orders");
                window.location.reload(false);
              }
              else {
                navigate("/");
                window.location.reload(false);
              }

            }
            else {
              setError(resp.data.message);
            }

          })
          .catch((err) => {
            console.log(err);
            if (err) {
              setError("There is something wrong in the order!!!");
            }
          });
      }


    }
    else {
      Swal.fire('Select Shipping & Payment Method')
    }

    event.preventDefault();
  };
  // console.log(area);
  return (
    <div className="checkout-page">
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}

      <div className="checkout-content-wrap">
        <div className="container-fluid">

          <div className="d-block d-sm-none">
            {/* For Mobile version */}
            <center>
              <div className="checkout-logo">
                <Link to="/">
                  <img
                    src={"/assets/images/logo/Original_logo.png"}
                    className="img-fluid"
                    width="150"
                    alt="Ultimate Organic Ecommerce Logo"
                  />
                </Link>
              </div>
            </center>
            <hr />
          </div>
          <div className="row">
            <div className="" id="main-checkout-product-info">
              <div className="row">
                <div className="order-1 order-md-2 col-lg-5 col-md-6 right">

                  <div id="cart-item" className="position-relative">
                    <div className="payment-info-loading" style={{ display: "none" }}>
                      <div className="payment-info-loading-content">
                        <i className="fas fa-spinner fa-spin"></i>
                      </div>
                    </div>

                    <div className="bg-light p-2">
                      <p className="font-weight-bold mb-0">Product(s):</p>
                    </div>
                    <div>
                      <div className="checkout-products-marketplace" id="shipping-method-wrapper">

                        <div className="mt-3 bg-light mb-3">
                          {!pId && (items.map((item, key) => {
                            return (
                              <div className="p-3" key={key}>
                                <div className="row cart-item">
                                  <div className="col-3">
                                    <div className="checkout-product-img-wrapper">
                                      <img
                                        className="item-thumb img-thumbnail img-rounded"
                                        src={item.image}
                                        alt="All Natural Italian-Style Chicken Meatballs"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <p className="mb-0">{item.name}</p>
                                    <p className="mb-0">{item.quantity}x</p>
                                  </div>
                                  <div className="col-4 text-end">
                                    <p>৳{Number(item.price) * Number(item.quantity)}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          }))}
                          {pId && (product.map((item, index) => {
                            return (
                              <div className="p-3" key={index}>
                                <div className="row cart-item">
                                  <div className="col-3">
                                    <div className="checkout-product-img-wrapper">
                                      <img
                                        className="item-thumb img-thumbnail img-rounded"
                                        src={item.product_image.small}
                                        alt="Product Image"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <p className="mb-0">{item.product_name}</p>
                                    <p className="mb-0">{qty}x</p>
                                  </div>
                                  <div className="col-4 text-end">
                                    <p>৳{Number(item.product_price) * Number(qty)}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          }))}
                          <hr />
                          <div className="shipping-method-wrapper p-3">
                            <div className="payment-checkout-form">
                              <div className="">
                                <h6>Shipping Method:</h6>
                                <br />
                              </div>
                              {/* <input type="hidden" name="shipping_option[3]" value="1" /> */}
                              <div id="shipping-method-3">
                                <ul className="list-group list_payment_method">
                                  <li className="list-group-item">
                                    <input
                                      className="magic-radio shipping_method_input"
                                      type="radio"
                                      name="shipping_method[3]"
                                      id="shipping-method-3-default-1"
                                      disabled
                                      value={"0.00"}
                                      // defaultChecked={shipingCost='0.00'}
                                      onClick={(e) => { setShipingCost(e.target.value); setShipingMethod(true); }}
                                    />
                                    <label htmlFor="shipping-method-3-default-1">
                                      Free shipping -<strong>Free shipping</strong>
                                    </label>
                                  </li>
                                  <li className="list-group-item">
                                    <input
                                      className="magic-radio shipping_method_input"
                                      type="radio"
                                      name="shipping_method[3]"
                                      id="shipping-method-3-default-2"
                                      value={shippingCharges && city !== null && city === 'outside_dhaka' ? (outsideShiCharge) : (insideShiCharge)}
                                      // value={"80.00"}
                                      // defaultChecked={shipingCost='80.00'}
                                      onClick={(e) => { setShipingCost(e.target.value); setShipingMethod(true); }}
                                      // checked
                                      // defaultChecked={city === "" ? false : true}

                                    />
                                    <label htmlFor="shipping-method-3-default-2">Local Courier - ৳{shippingCharges && city !== null && city === 'outside_dhaka' ? (outsideShiCharge) : (insideShiCharge)}</label>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="payment-method-wrapper p-3">
                            <div className="payment-checkout-form">
                              <div className="mx-0">
                                <h6>Payment method:</h6>
                                <br />
                              </div>
                              <div id="payment-method-3">
                                <ul className="list-group list_payment_method">
                                  <li className="list-group-item">
                                    <input
                                      className="magic-radio payment_method_input"
                                      type="radio"
                                      name="payment_method"
                                      id="cod"
                                      defaultChecked={city !== 'outside_dhaka' && city === 'inside_dhaka' && city !== null}
                                      value={"cod"}
                                      onClick={(e) => setPaymentMethod(e.target.value)}
                                      // checked={city === 'inside_dhaka'}
                                      checked={city === 'outside_dhaka' ? false : null}
                                      disabled={city === 'outside_dhaka'}
                                    />
                                    <label htmlFor="cod">Cash On Delivery</label>
                                  </li>
                                  <li className="list-group-item">
                                    <input
                                      className="magic-radio payment_method_input"
                                      type="radio"
                                      name="payment_method"
                                      id="sslcommerz"
                                      value={"sslcommerz"}
                                      onClick={(e) => setPaymentMethod(e.target.value)}
                                      defaultChecked={city === 'inside_dhaka' || city === 'outside_dhaka'}
                                    />
                                    <label htmlFor="sslcommerz">Sslcommerz</label>
                                  </li>
                                  <li className="list-group-item">
                                    <input
                                      className="magic-radio payment_method_input"
                                      type="radio"
                                      name="payment_method"
                                      id="bkash"
                                      value={"bkash"}
                                      onClick={(e) => setPaymentMethod(e.target.value)}
                                      defaultChecked={city === 'inside_dhaka' || city === 'outside_dhaka'}
                                    />
                                    <label htmlFor="bkash">Bkash</label>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2 p-2">
                      <div className="row">
                        <div className="col-6">
                          <p>Subtotal:</p>
                        </div>
                        <div className="col-6">
                          <p className="price-text sub-total-text text-end"> ৳{totalPrice} </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p>Shipping fee:</p>
                        </div>
                        <div className="col-6 float-end">
                          <p className="price-text shipping-price-text text-end">৳ {shipingCost}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p>Tax:</p>
                        </div>
                        <div className="col-6 float-end">
                          <p className="price-text tax-price-text text-end">৳ {vat}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p>Discount:</p>
                        </div>
                        <div className="col-6 float-end">
                          <p className="price-text tax-price-text text-end">৳ {discount ? discount : 0.00}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p>
                            <strong>Total</strong>:
                          </p>
                        </div>
                        <div className="col-6 float-end">
                          <p className="total-text raw-total-text text-end">
                            {" "}
                            ৳{Number(totalPrice) + Number(shipingCost) + Number(vat) - Number(discount)}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {couponID && (
                    <>
                    <p className="ps-3">Applied Coupon: <span className="text-warning"><b>{coupon}</b></span><Link className="ms-3" to={'#'}
                    onClick={() => {setCoupon('');setCouponID(null);setDiscount('');} }>Remove</Link></p>
                    <hr/>
                    </>
                  )}
                  <div className="mt-3 mb-5">
                    <div className="checkout-discount-section mb-2">
                      <Link to="#" className="ps-3 btn-open-coupon-form">
                        You have a coupon code?
                      </Link>
                    </div>
                    <div className="coupon-wrapper">
                      <div className="row promo coupon coupon-section">
                        <div className="col-lg-8 col-md-8 col-8">
                          <input
                            type="text"
                            name="coupon_code"
                            className="form-control coupon-code input-md checkout-input"
                            value={coupon}
                            placeholder="Enter coupon code..."
                            onChange={(e) => setCoupon(e.target.value)}
                          />
                          <div className="coupon-error-msg">
                            <span className="text-danger"></span>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4 text-end">
                          <button
                            className="btn btn-md btn-gray btn-info apply-coupon-code float-end"
                            data-url=""
                            type="button"
                            style={{ marginTop: "0", padding: "10px 20px" }}
                            onClick={() => {applyCoupon();}}
                          >
                            <i className="fi-rs-label mr-10"></i>Apply
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-6 left">
                  <div className="d-none d-sm-block">
                    <div className="checkout-logo">
                      <div className="">
                        <Link to="/">
                          <img
                            src={"/assets/images/logo/Original_logo.png"}
                            className="img-fluid"
                            width="150"
                            alt="Ecommerce"
                          />
                        </Link>
                      </div>
                    </div>
                    <hr />
                  </div>
                  {changeAddress ? (
                    <div id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab" class="tab-pane fade active show">
                      {addresses &&
                        addresses.map((address) => {
                          return (
                              <div class="row p-2">
                                <div class="col-lg-12 dashboard-address-item  is-address-default ">
                                  <div class="card h-100 mb-3 mb-lg-0 mb-2">
                                    <div class="card-header">
                                      <h5 class="mb-0">
                                        {address.name} &nbsp;
                                        {address.is_default === '1' ? (
                                          <small class="badge bg-primary" style={{ float: 'right' }}>Default</small>
                                        ) : (
                                          <button style={{ float: 'right' }} className="btn" onClick={() => handleDefaultAddressChange(address.id)}>Select This Address</button>
                                        )}
                                      </h5>
                                    </div>{" "}
                                    <div class="card-body p-0">
                                      <address className='row'>
                                        <p className="col-6">Phone: {address.phone}</p>
                                        <p className="col-6">Email: {address.email}</p>
                                        <p className="col-6">Address: {address.address}</p>
                                        <p className="col-6">Area: {address.area}</p>
                                        <p className="col-6">City: {address.city}</p>
                                        <p className="col-6">Zip: {address.zip}</p>
                                      </address>{" "}
                                    </div>{" "}
                                  </div>
                                </div>{" "}
                              </div>
                          );
                        })}
                      <button className="btn btn-warning m-3" onClick={() => { setChangeAddress(false); }}>Go Back</button>
                    </div>
                  ) : (
                    <div>
                      {defaultAddress.id && (
                        //Logged in shipping information
                        <div className="card">
                          <h5 className="checkout-payment-title p-4">Shipping information</h5>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="card h-100 mb-3 mb-lg-0 mb-2">
                                <div className="card-header">
                                  <h5 className="mb-0">
                                    {defaultAddress.name} &nbsp;
                                    <small className="badge bg-primary">Default</small>
                                  </h5>
                                </div>{" "}
                                <div className="card-body">
                                  <address className="row">
                                    <p className="col-6">Phone: {defaultAddress.phone}</p>
                                    <p className="col-6">Email: {defaultAddress.email}</p>
                                    <p className="col-6">Address: {defaultAddress.address}</p>
                                    <p className="col-6">Area: {defaultAddress.shipping_area.area_name}</p>
                                    <p className="col-6">City: {defaultAddress.shipping_state.name}</p>
                                    <p className="col-6">Zip: {defaultAddress.zip}</p>
                                    <div className="col-6">
                                      <button className="btn mt-3" onClick={() => handleAddressChange()}>Change Address</button>
                                    </div>
                                    <div className="col-6">
                                      <Link to={'/customer/add-address'} className="mt-3 btn">Add New Address</Link>
                                    </div>
                                  </address>{" "}
                                </div>{" "}
                              </div>
                            </div>{" "}
                          </div>{" "}
                          <form className="p-4" onSubmit={(e) => { checkOutSubmit(e); }}>
                            <div className="form-group mb-3">
                              <label htmlFor="description" className="control-label">
                                Order notes
                              </label>
                              <br />
                              <textarea
                                name="description"
                                id="description"
                                className="form-control"
                                rows={4}
                                placeholder="Notes about your order, e.g. special notes for delivery."
                                value={orderNote}
                                onChange={(e) => setOrderNote(e.target.value)}
                              />
                            </div>
                            <div className="form-group mb-3">
                              <div className="row">
                                <div className="col-md-6 d-none d-md-block" style={{ lineHeight: "53px" }}>
                                  <Link to="/cart" className="text-info">
                                    <i className="fas fa-long-arrow-alt-left"></i> <span className="d-inline-block back-to-cart">Back to cart</span>
                                  </Link>
                                </div>
                                <div className="col-md-6 checkout-button-group">
                                  <button
                                    type="submit"
                                    className="btn payment-checkout-btn payment-checkout-btn-step float-end"
                                    data-processing-text="Processing. Please wait..."
                                    data-error-header="Error"
                                  >
                                    Checkout
                                  </button>
                                </div>
                              </div>

                              <div className="d-block d-md-none back-to-cart-button-group">
                                <Link className="text-info" to="/cart">
                                  <i className="fas fa-long-arrow-alt-left"></i> <span className="d-inline-block">Back to cart</span>
                                </Link>
                              </div>
                            </div>
                          </form>
                        </div>

                      )}
                    </div>
                  )}
                  {!defaultAddress.id && !isLoggedIn && (
                    //outside users
                    <form onSubmit={(e) => { checkOutSubmit(e); }}>
                      <div className="form-checkout">
                        <div>
                          <h5 className="checkout-payment-title">Shipping information</h5>
                          <div className="customer-address-payment-form">
                            <div className="form-group mb-3">
                              <p>
                                Already have an account? <Link to="/login">Login</Link>
                              </p>
                            </div>
                            <div className="address-form-wrapper">
                              <div className="row">
                              <span className='text-danger ps-1'>{errorList.shipping_details && errorList.shipping_details}</span>
                                <div className="col-12">
                                  <div className="form-group mb-3 ">
                                    <input
                                      type="text"
                                      name="name"
                                      id="name"
                                      placeholder="Full Name*"
                                      className="form-control address-control-item address-control-item-required checkout-input"
                                      required
                                      value={username}
                                      onChange={(e) => setUsername(e.target.value)}
                                    />
                                    {errorList.username !== '' &&
                                      <span style={{ color: 'red' }}>{errorList.username}</span>}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-8 col-12">
                                  <div className="form-group  ">
                                    <input
                                      type="text"
                                      name="email"
                                      id="address_email"
                                      placeholder="Email"
                                      required
                                      className="form-control address-control-item address-control-item-required checkout-input"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {errorList.email !== '' &&
                                      <span style={{ color: 'red' }}>{errorList.email}</span>}
                                  </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                  <div className="form-group  ">
                                    <input
                                      type="text"
                                      name="address[phone]"
                                      id="address_phone"
                                      placeholder="Phone "
                                      required
                                      className="form-control address-control-item address-control-item-required checkout-input"
                                      value={contact}
                                      onChange={(e) => setContact(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6 col-12">
                                  <div className="form-group mb-3">
                                    <select style={{ height: '64px' }} className="form-control address-control-item address-control-item-required"
                                      name="city"
                                      value={city}
                                      required={city === '' ? true : false}
                                      onChange={(e) => { setCity(e.target.value); if (e.target.value === 'inside_dhaka') { setShipingCost(insideShiCharge); } else if (e.target.value === 'outside_dhaka') { setShipingCost(outsideShiCharge); setPaymentMethod(''); } else { setShipingCost('0.00'); } }}
                                    >
                                      <option value={''}>Your City</option>
                                      <option value={'inside_dhaka'}>Inside Dhaka</option>
                                      <option value={'outside_dhaka'}>Outside Dhaka</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                  <div className="form-group">
                                    <input
                                      id="address_city"
                                      type="text"
                                      className="form-control address-control-item address-control-item-required checkout-input"
                                      placeholder="Zip"
                                      required
                                      name="zip"
                                      value={zip}
                                      onChange={(e) => setZip(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="col-12">
                                  {city === 'inside_dhaka' && (
                                    <div className="form-group ">
                                      <select style={{ height: '64px' }} className="form-control address-control-item address-control-item-required"
                                        name="area"
                                        required
                                        value={area}
                                        onChange={(e) => setArea(e.target.value)}
                                      >
                                        <option value={null}>Select Area</option>
                                        {shippingZones &&
                                          (shippingZones.map((srvzn, index) => {
                                            if (srvzn.shipping_rule_id === 1) {
                                              return <option key={index} value={srvzn.id}>{srvzn.area_name}</option>;
                                            }
                                          }))}
                                      </select>
                                    </div>
                                  )}
                                  {city === 'outside_dhaka' && (
                                    <div className="form-group ">
                                      <select style={{ height: '64px' }} className="form-control address-control-item address-control-item-required"
                                        name="area"
                                        value={area}
                                        required
                                        onChange={(e) => setArea(e.target.value)}
                                      >
                                        <option value={null}>Select Area</option>
                                        {shippingZones &&
                                          shippingZones.map((srvzn, index) => {
                                            if (srvzn.shipping_rule_id === 2) {
                                              return <option key={index} value={srvzn.id}>{srvzn.area_name}</option>;
                                            }
                                          })}
                                      </select>
                                    </div>
                                  )}
                                </div>
                                <div className="col-12">
                                  <div className="form-group mb-3 ">
                                    <input
                                      id="address_address"
                                      type="text"
                                      className="form-control address-control-item address-control-item-required checkout-input"
                                      placeholder="Address"
                                      name="address"
                                      required
                                      value={address}
                                      onChange={(e) => setAddress(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="form-group mb-3">
                          <label htmlFor="description" className="control-label">
                            Order notes
                          </label>
                          <br />
                          <textarea
                            name="description"
                            id="description"
                            className="form-control"
                            rows={4}
                            placeholder="Notes about your order, e.g. special notes for delivery."
                            value={orderNote}
                            onChange={(e) => setOrderNote(e.target.value)}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <div className="row">
                            <div className="col-md-6 d-none d-md-block" style={{ lineHeight: "53px" }}>
                              <Link to="/cart" className="text-info">
                                <i className="fas fa-long-arrow-alt-left"></i> <span className="d-inline-block back-to-cart">Back to cart</span>
                              </Link>
                            </div>
                            <div className="col-md-6 checkout-button-group">
                              <button
                                type="submit"
                                className="btn payment-checkout-btn payment-checkout-btn-step float-end"
                              >
                                Checkout
                              </button>
                            </div>
                          </div>

                          <div className="d-block d-md-none back-to-cart-button-group">
                            <Link className="text-info" to="/cart">
                              <i className="fas fa-long-arrow-alt-left"></i> <span className="d-inline-block">Back to cart</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) }
                   {!defaultAddress.id && isLoggedIn && 
                      (
                        <div className="form-checkout">
                          <div className="col-12">
                              <div className="alert alert-dark d-flex align-items-center" role="alert">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="currentColor"
                                  className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                  viewBox="0 0 16 16"
                                  role="img"
                                  aria-label="Info:"
                                >
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                </svg>
                                <div>
                                 <h6>Dear {customer_name} You did not added any address yet. Add address first!</h6>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 m-2">
                              <Link className="add-address" to="/customer/add-address">
                                <i className="fa fa-plus"></i>
                                <Link to={'/customer/add-address'} className="mt-3 btn">Add New Address</Link>
                              </Link>
                            </div>
                          {/* <h6>Dear {customer_name} You haven't added any address yet. Add address first</h6>
                        <Link to={'/customer/add-address'} className="mt-3 btn">Add New Address</Link> */}
                          </div>
                      )
                   }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
    </div>
  );
};

export default Checkout;