import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/home.";
import ProductDetails3 from "./pages/productDetails3";
import AllProducts from "./pages/AllProducts";
import Login from "./pages/login";
import Register from "./pages/register";
import Overview from "./pages/customerCurd/overview";
import Profile from "./pages/customerCurd/profile";
import Dashboard from "./pages/customerCurd/dashboard";
import Orders from "./pages/customerCurd/orders";
import Address from "./pages/customerCurd/address";
import ChangePass from "./pages/customerCurd/changePass";
import BecomeVendor from "./pages/customerCurd/becomeVendor";
import BrowserPage from "./pages/browserPage";
import "./App.css";
import Cart from "./pages/Cart";
import Checkout from "./pages/checkout";
import Payment from "./pages/payment";
import CreateAddress from "./pages/customerCurd/createAddress";
import AddressEdit from "./pages/customerCurd/addressEdit";
import Contact from './pages/contact';
import Faq from './pages/faq';
import '../src/assests/Css/loadingAnimation.css';
import FlashSell from "./pages/FlashSell";
import AboutUs from "./pages/AboutUs";
import TermsCondition from "./pages/TermsCondition";
import CookiePolicy from "./pages/CookiePolicy";
import ReturnExchange from "./pages/ReturnExchange";
import ShippingDelivery from "./pages/ShippingDelivery";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import OtpVerify from "./pages/OtpVerify";
import PaymentSuccess from "./pages/PaymentSuccess";
import Wishlist from "./pages/Wishlist";
import CustomerDashboard from "./pages/CustomerDashboard";
import Blogs from "./pages/Blogs";
import TrackOrder from "./pages/TrackOrder";
import ForgetPassword from "./pages/ForgetPassword";
import BlogView from "./pages/BlogView";
import OtpNewPassword from "./pages/OtpNewPassword";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/ctf/:id" render={({ match }) => <Ctf id={match.params.id} />} /> */}

        {/*  <Route exact path="/cart" element={<Cart />} /> */}
    
        <Route exact path="/" element={<Home />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/otp-verification/:id" element={<OtpVerify />} />
        <Route exact path="/product_details/:id" element={<ProductDetails3 />} />
        <Route exact path="/product_details/:id/:name" element={<ProductDetails3 />} />
        <Route exact path="/login" element={<Login />} />

        {/* <Route exact path="/customer/overview" element={<Overview />} /> */}
        <Route exact path="/customer/edit-account" element={<Profile />} />
        {/* <Route exact path="/customer/orders" element={<Orders />} /> */}
        {/* <Route exact path="/customer/address" element={<Address />} /> */}
        <Route exact path="/customer/changepass" element={<ChangePass />} />
        {/* <Route exact path="/customer/becomevendor" element={<BecomeVendor />} /> */}
        <Route exact path="/products" element={<AllProducts />} />
        <Route exact path="/flash-sell" element={<FlashSell />} />

        {/* <Route exact path="/customer/address/edit/:id" element={<AddressEdit />} />
        <Route exact path="/customer/address/add" element={<CreateAddress />} /> */}
        <Route exact path="/payment" element={<Payment />} />
        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/checkout/:pId/:qty" element={<Checkout />} />
        {/* <Route exact path="/dash" element={<Dashboard />} /> */}
        <Route exact path="/customer/:pgType" element={<CustomerDashboard />} />
        <Route exact path="/customer/:pgType/:add_id" element={<CustomerDashboard />} />
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/product-categories/:id/:cname" element={<BrowserPage />} />

        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/blog/:id" element={<BlogView />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/terms-conditions" element={<TermsCondition />} />
        <Route exact path="/cookie-policy" element={<CookiePolicy />} />
        <Route exact path="/returns-exchanges" element={<ReturnExchange />} />
        <Route exact path="/shipping-delivery" element={<ShippingDelivery />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route exact path="/forgot-password" element={<ForgetPassword />} />
        <Route exact path="/verify-otp-set-new-password/:customer_id" element={<OtpNewPassword />} />
        
        <Route exact path="/payment_success" element={<PaymentSuccess />} />
        <Route exact path="/wish-list" element={<Wishlist />} />

        <Route exact path="/track-order" element={<TrackOrder />} />

      </Routes>
    </Router>
  );
}

export default App;
