import React from "react";
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import ReactCaptcha from 'modern-react-captcha';
// import Parser from 'html-react-parser';
import RefreshIcon from '../assests/images/Icon/refresh-icon.png';
import '../assests/Css/contact.css';
import Swal from "sweetalert2";
import axios from "axios";

const Contact = () => {
  const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life - Contact";
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, []);

  const [captchaError, setCaptchaError] = useState(0);

  const handleSuccess = () => {
    console.log('Captcha matched!');
    setCaptchaError(1);
  };

  const handleFailure = () => {
    console.log('captcha not matched');
    setCaptchaError(2);
  };

  const [errorList, setError] = useState([]);
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [address, setAddress] = useState("");
  let [phone, setPhone] = useState("");
  let [subject, setSubject] = useState("");
  let [message, setMessage] = useState("");
  
  const handleContactUs = () => {
    if(captchaError==0){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Fillup The Captcha to Proceed',
        // showConfirmButton: false,
        // timer: 2000
      });
    }
    else if(captchaError==2){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Correctly Fillup The Captcha to Proceed',
        // showConfirmButton: false,
        // timer: 2000
      });
    }
    else{
      axios
        .post("contact-us", {
          name:name,
          email:email,
          address:address,
          phone:phone,
          subject:subject,
          message:message
        })
        .then(function (resp) {
          if (resp.data.success) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: resp.data.message,
              // showConfirmButton: false,
              // timer: 2000
            });
            setName('');
            setEmail('');
            setAddress('');
            setPhone('');
            setSubject('');
            setMessage('');
          }
          else{
            setError(resp.data.message);
          }
        })
        .catch((err) => {
          setError(err.response.data.errors);
        });
    }
  }

  return (
    <div>
      {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
        <Layout>
        <div className="page-header breadcrumb-wrap">
            <div className="breadcrumb" itemScope itemType="">
              <Link to="/" itemProp="item" title="Home">
                Home
                <meta itemProp="name" content="Home" />
                <meta itemProp="position" content="1" />
              </Link>
              <span></span> Contact
              <meta itemProp="name" content="Account information" />
              <meta itemProp="position" content="2" />
            </div>
        </div>
          <section className="mt-60">
            <p></p>
            <div style={{ height: "400px", width: " 100%", position: "relative", textAlign: "right" }}>
              <div style={{ height: "400px", width: "100%", overflow: "hidden", background: "none !important" }}>
                <iframe
                  title="Map"
                  width="100%"
                  height="500"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d456.4420935532579!2d90.43296898907721!3d23.763893603827906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c7f56a95a617%3A0x7c3d0ad7fd7cec31!2sSiraj%20Convention%20Center!5e0!3m2!1sen!2sbd!4v1663095655374!5m2!1sen!2sbd"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
                
              </div>
            </div>
            <p></p>
            <p></p>
            <div className="mt-50 pb-50">
              <div className="row">
                <div className="col-12">
                  <h4 className="mb-15 text-muted">Address</h4>
                  39 (Shiraj Convention Center), Aftabnagar Nagar Main Road, Block#C, Aftabnagar, Badda, Dhaka, Bangladesh 1212
                  <br /> <abbr title="Phone">Phone:</abbr> (+88) 0961366444
                  <br /> <abbr title="Email">Email: </abbr>ultimateorganiclife@gmail.com
                  <br />{" "}
                  <a
                    href="https://goo.gl/maps/429WsbudmY7Qyq8aA"
                    className="btn btn-outline btn-sm btn-brand-outline font-weight-bold text-brand bg-white text-hover-white mt-20 border-radius-5 btn-shadow-brand hover-up"
                    target="_blank"
                  >
                    <i className="fa fa-map text-muted mr-15"></i>View map
                  </a>
                </div>{" "}
              </div>
            </div>{" "}
            <hr /> <p></p>
            <p></p>
            <section className="mt-50 pb-50 ">
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className="col-xl-8 col-lg-10 m-auto">
                    <div className="contact-from-area  padding-20-row-col wow tmFadeInUp animated animated" style={{ visibility: "visible" }}>
                      <h3 className="mb-10 text-center">Drop Us a Line</h3>{" "}
                      <p className="text-muted mb-30 text-center font-sm">Contact Us For Any Questions</p>{" "}
                      {/* <form method="POST" action="#" acceptCharset="UTF-8" className="contact-form-style text-center contact-form"> */}
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="input-style mb-20">
                              <input name="name" value={name} placeholder="Name*" type="text" onChange={(e)=>setName(e.target.value)} required />
                              <span className='text-danger ps-1'>{errorList.name}</span>
                            </div>
                          </div>{" "}
                          <div className="col-lg-6 col-md-6">
                            <div className="input-style mb-20">
                              <input type="email" name="email" value={email} placeholder="Email*" onChange={(e)=>setEmail(e.target.value)} required />
                              <span className='text-danger ps-1'>{errorList.email}</span>
                            </div>
                          </div>{" "}
                          <div className="col-lg-6 col-md-6">
                            <div className="input-style mb-20">
                              <input name="address" value={address} placeholder="Address*" type="text" onChange={(e)=>setAddress(e.target.value)} required />
                              <span className='text-danger ps-1'>{errorList.address}</span>
                            </div>
                          </div>{" "}
                          <div className="col-lg-6 col-md-6">
                            <div className="input-style mb-20">
                              <input name="phone" value={phone} placeholder="Phone*" type="tel" onChange={(e)=>setPhone(e.target.value)} required />
                              <span className='text-danger ps-1'>{errorList.phone}</span>
                            </div>
                          </div>{" "}
                          <div className="col-lg-12 col-md-12">
                            <div className="input-style mb-20">
                              <input name="subject" value={subject} placeholder="Subject*" type="text" onChange={(e)=>setSubject(e.target.value)} required />
                              <span className='text-danger ps-1'>{errorList.subject}</span>
                            </div>
                          </div>{" "}
                          <div className="col-lg-12 col-md-12">
                            <div className="textarea-style mb-20">
                              <textarea name="content" placeholder="Message*" value={message} onChange={(e)=>setMessage(e.target.value)} required />
                              <span className='text-danger ps-1'>{errorList.message}</span>
                            </div>
                          </div>{" "}
                        
                          <div className="col-lg-12 col-md-12">
                            <div className="input-style">
                            
                            <ReactCaptcha charset='ul' length={6} color='white' bgColor='black' reload={true}
				                    reloadIcon={RefreshIcon}
				                    reloadText={''}
                            handleSuccess={handleSuccess} handleFailure={handleFailure}
                            />
                            {captchaError==2 && (<span className="text-danger">Captcha does not match</span>)}
                            </div>
                          </div>{" "}
                          <div className="col-lg-12 col-md-12">
                            <button type="submit" className="submit submit-auto-width mt-30" onClick={()=>handleContactUs()}>
                              Send message
                            </button>
                          </div>
                        </div>{" "}
                        <div className="form-group text-left">
                          <div className="contact-message contact-success-message mt-30" style={{ display: "none" }}></div>{" "}
                          <div className="contact-message contact-error-message mt-30" style={{ display: "none" }}></div>
                        </div>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>{" "}
            <p></p>
          </section>
        </Layout>
    </div>
  );
};

export default Contact;
