import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';

const ShippingDelivery = () => {
  const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life - Shipping & Delivery";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [shippingDelivery, setShippingDelivery] = useState();
  useEffect(() => {
    axios.get("shipping-delivery")
      .then(resp => {
        // console.log(resp.data.description);
        setShippingDelivery(resp.data.description);
      });
  }, []);

  return (
    <div>
      {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
      <Layout>
      <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span> Shipping & Delivery
            <span></span>
          </div>
        </div>
        <br/>
        <div className="row justify-content-center">
        <div className="col-12 pt-1 pb-4">
        {shippingDelivery && (
            Parser(shippingDelivery)
            )}
          </div>
        </div>

      </Layout>
    </div>
  );
};

export default ShippingDelivery;
