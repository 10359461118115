import React, { useState, useEffect } from "react";
import { useCart } from "react-use-cart";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import '../assests/Css/mobile-menu.css'
// import CartPopup from "./cartPopup";
import Swal from "sweetalert2";

import cartIcon from '../assests/images/Icon/Cart.svg';
import homeIcon from '../assests/images/Icon/Home.svg';
import menuIcon from '../assests/images/Icon/Menu.svg';
import searchIcon from '../assests/images/Icon/Search.svg';
import userIcon from '../assests/images/Icon/User.svg';

const MobileMenu = (props) => {
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const [loginToggle, setLoginToggle] = useState(false);
  const [cartToggle, setCartToggle] = useState(false);
  const {
    isEmpty,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
  } = useCart();

  let totalPrice = 0;

  items.forEach(function (item) {
    totalPrice += item.quantity * item.price;
  });

  let navigate = useNavigate();
  var isLoggedIn = false;
  var user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    isLoggedIn = true;
  }
  const logout = () => {
    if (user) {
      axios.post("customer/logout")
        .then(function (resp) {
          // console.log(resp.data);
          if (resp.data.success) {
            localStorage.removeItem("user");
            Swal.fire({
              position: 'center',
              icon: 'info',
              title: 'Successfully logged out',
              showConfirmButton: false,
              timer: 1500
            });
            navigate("/");

          }
          // console.log(defaultAddress);
        })
        .catch((err) => {
          console.log(err);
        });

    } else {
      navigate("/");
    }
    isLoggedIn = false;
  };

  const [productName, setProductName] = useState("");
  const [products, setProducts] = useState([]);
  useEffect(() => {
    axios.get("products/search?product_name=" + productName)
      .then(resp => {
        setProducts(resp.data.data.data);
      });
  }, [productName]);

  let [username, setUserName] = useState("");
  let [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const loginSubmit = (e) => {
    var obj = { user_input: username, password: password };
    axios
      .post("customer/login", obj)
      .then(function (resp) {
        console.log(resp.data);
        var data = resp.data;

        if (data.success == false) {
          setError(resp.data.message);
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: resp.data.message,
            showConfirmButton: false,
            timer: 2000
          })
        }
        // console.log(localStorage.getItem("user"));
        if (data.status) {
          var user = { token: data.token, customer_id: data.data.id, customer_name: data.data.customer_name, user: data.data };
          localStorage.setItem("user", JSON.stringify(user));
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successfully logged in',
            showConfirmButton: false,
            timer: 1500
          })
          navigate("/");
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log("error : " + err);
      });
    e.preventDefault();
  };

  return (
    <div className="display_m_menu">

      <div className=" mobile-promotion">
        <span>
          Grand opening, <strong>up to 15%</strong> off all items. Only <strong>3 days</strong> left
        </span>
      </div>
      <div className="header-bottom header-bottom-bg-color  sticky-bar ">
        <div className="container">
          <div className="header-wrap header-space-between position-relative">

            <div className="logo logo-width-1 d-block d-lg-none">
              <Link to="/">
                <img
                  src="/assets/images/logo/Original_logo.png"
                  style={{ width: '85px' }}
                  alt="Ultimate Organic Ecommerce"
                />
              </Link>
            </div>

            <div className="header-nav d-none d-lg-flex"></div>

            {/* <div className="header-action-icon-2 d-block d-lg-none">
              <a href="#">
                <div className="burger-icon burger-icon-white" onClick={() => setToggle(!toggle)}>
                  <span className="burger-icon-top"></span>
                  <span className="burger-icon-mid"></span>
                  <span className="burger-icon-bottom"></span>
                </div>
              </a>
            </div> */}
            <div className="header-action-right d-block d-lg-none">
              <div className="header-action-2">
                {/* <div className="header-action-icon-2">
                  <a href="#">
                    <img alt="Compare" src="https://nest.botble.com/themes/nest/imgs/theme/icons/icon-compare.svg" />
                    <span className="pro-count white compare-count">0</span>
                  </a>
                </div>
                <div className="header-action-icon-2">
                  <a href="#">
                    <img alt="Wishlist" src="https://nest.botble.com/themes/nest/imgs/theme/icons/icon-heart.svg" />
                    <span className="pro-count white wishlist-count"> 0 </span>
                  </a>
                </div> */}
                {/* <div className="header-action-icon-2">
                  <Link className="mini-cart-icon" to="/cart">
                    <img alt="Cart" src={themeCart} />
                    <span className="pro-count white">{totalUniqueItems}</span>
                  </Link>
                  <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                    {totalUniqueItems === 0 && (
                      <span>No products in the cart.</span>
                    )}
                    {totalUniqueItems !== 0 &&
                      <div className="">
                        {items.map((item, key) => {
                          return (
                            <div key={key}>
                              <ul key={key}>
                                <li key={key}>
                                  <div className="shopping-cart-img">
                                    <a href="#">
                                      <img alt="" src={item.image} />
                                    </a>
                                  </div>
                                  <div className="shopping-cart-title">
                                    <h4>
                                      <Link to={"/product_details/" + item.id}>{item.name} </Link>
                                    </h4>
                                    <h3>
                                      {" "}
                                      <span className="d-inline-block">{item.price * item.quantity} ৳</span> <small></small>
                                    </h3>
                                    <p className="mb-0"></p>
                                  </div>
                                  <div className="shopping-cart-delete" onClick={() => removeItem(item.id)}>
                                    <a href="#" data-url="" className="remove-cart-item" onClick={() =>
                                      Swal.fire({
                                        position: 'top-end',
                                        icon: 'warning',
                                        title: 'Product removed form the cart',
                                        showConfirmButton: false,
                                        timer: 800
                                      })
                                    }>
                                      <i className="fi-rs-cross-small"></i>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                        <div className="shopping-cart-footer">
                          <div className="shopping-cart-total">
                            <h5>
                              <strong className="d-inline-block">Sub Total:</strong>
                              <span>{totalPrice} ৳</span>
                            </h5>
                            <div className="clearfix"></div>
                            <h5>
                              <strong className="d-inline-block">Tax:</strong> <span>-- ৳</span>
                            </h5>
                            <div className="clearfix"></div>
                            <h4>
                            </h4>
                          </div>
                          <div className="shopping-cart-button">
                            <Link to="/cart">View cart</Link>
                            <Link to="/checkout">Checkout</Link>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Sticky Mobile Bottom navbar start */}
      <div className="mobile_bottom_navbar">
        <Link to="#" onClick={() => { setLoginToggle(false); setToggle(!toggle); }}><img src={menuIcon} /> </Link>
        <Link to="#" onClick={() => { setLoginToggle(false); setSearchToggle(!searchToggle); }}><img src={searchIcon} /></Link>
        <Link to="/"><img src={homeIcon} /></Link>
        <Link to="#" onClick={() => { setLoginToggle(false); setSearchToggle(false); setToggle(false); setCartToggle(!cartToggle); }}>
          <div className="mobile_cart">
            <img src={cartIcon} />
            <span className="mobile_cart_count">{totalUniqueItems}</span>
          </div>
        </Link>
        {isLoggedIn ? (
          <Link to="/customer/dashboard"><img src={userIcon} /></Link>
        ) :
          (
            <Link to="#" onClick={() => { setSearchToggle(false); setLoginToggle(!loginToggle); setCartToggle(false); }}><img src={userIcon} /></Link>
          )}
      </div>

      {/* Sticky Mobile Bottom navbar end */}

      {/* {toggle && ( */}
      <div className={toggle ? "mobile-header-active mobile-header-wrapper-style sidebar-visible .topnav" : "mobile-header-active mobile-header-wrapper-style .topnav"} id="myLinks">
        <div className="mobile-header-wrapper-inner pb-0">
          <div className="mobile-header-top">

            <div className="mobile-header-logo">
              <Link to="/">
                <img src="/assets/images/logo/Original_logo.png" alt="Ultimate Ecommerce" />
              </Link>
            </div>

            <a href="#" onClick={() => setToggle(!toggle)}>
              <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                <button className="close-style search-close">
                  <i className="icon-top"></i>
                  <i className="icon-bottom"></i>
                </button>
              </div>
            </a>
          </div>
          <div className="mobile-header-content-area">
            {/* <div className="mobile-search search-style-3 mobile-header-border">
              <form
                action=""
                className="form--quick-search"
                data-ajax-url=""
                method="get"
              >
                <input type="text" name="search" className="input-search-product" placeholder="Search for items…" value={productName}
                  onChange={(e) => { setProductName(e.target.value); }}
                  onClick={(e) => { setProductName(e.target.value); }} />
                <button type="submit">
                  <i className="fi-rs-search"></i>
                </button>
                <div className={productName ? 'panel--search-result active' : 'panel--search-result'}>
                  <div className="panel__content ">
                    <div className="row py-2 mx-0 ">
                      {products && products.map((product, index) => {
                        return (
                          <div key={index} className="col-12 px-1 px-md-2 py-1 product-cart-wrap border-0 rounded-0">
                            <div className="row mx-md-2 gx-md-2 gx-1">
                              <div className="col-xl-2 col-3 product-img-action-wrap mb-0">
                                <div className="product-img product-img-zoom">
                                  <Link to={"/product_details/" + product.id}>
                                    <img className="default-img" src={product.image && (product.image.small)} alt={product} />
                                  </Link>
                                </div>
                              </div>
                              <div className="col-xl-10 col-9 product__content">
                                <div className="product-content-wrap px-1 px-md-3">
                                  <Link className="product__title" to={"/product_details/" + product.id}>{product.name}</Link>
                                 
                                  {product.discount > 0 ? (
                                    <div className="product-price">
                                      <span>{product.formatted_final_product_price}</span>
                                      <span className="old-price">{product.formatted_regular_price}</span>
                                    </div>
                                  ) : (
                                    <div className="product-price">
                                      <span>{product.formatted_final_product_price}</span>
                                    
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    </div>

                  </div>
                  <div className="panel__footer text-center">
                    
                  </div>
                </div>
              </form>
            </div> */}
            <div className="mobile-menu-wrap mobile-header-border">
              <nav>
                <ul className="mobile-menu">
                  <li className=" menu-item-has-children  ">
                    <span className="menu-expand"></span>
                    <Link to="/" target="_self">
                      Home
                    </Link>
                  </li>
                  <li className=" menu-item-has-children  " onClick={() => setToggle2(!toggle2)}>
                    <span className="menu-expand">{toggle2 ? <i className="fi-rs-angle-up"></i> : <i className="fi-rs-angle-down"></i>}</span>
                    <span className="menu-expand"></span>
                    <a href="#">Categories</a>
                    {toggle2 && (
                      <ul className="dropdown" style={{ display: "" }}>
                        {(props.categories.map((catgs, index) => {
                          return (
                            catgs.categories.map((category, index) => {
                              return (
                                <li key={index}>
                                  <Link className="" to={"/product-categories/" + category.id + '/' + category.category_name}>

                                    <span>{category.category_name}</span>
                                  </Link>
                                </li>
                              );
                            })
                          )
                        })
                        )}

                      </ul>
                    )}
                  </li>
                  <li className=" menu-item-has-children  ">
                    <span className="menu-expand"></span>
                    <Link to="/products" target="_self">
                      Products
                    </Link>
                  </li>

                  <li className=" menu-item-has-children  ">
                    <span className="menu-expand"></span>
                    <Link to="/track-order">
                      <span>Order Tracking</span>
                    </Link>
                  </li>

                  <li className=" menu-item-has-children  ">
                    <span className="menu-expand"></span>
                    <Link to="/blogs" target="_self">
                      Blogs
                    </Link>
                  </li>
                  <li className=" ">
                    <Link to="/faq" target="_self">
                      FAQ
                    </Link>
                  </li>
                  <li className=" ">
                    <Link to="/contact" target="_self">
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="mobile-header-info-wrap">
              {!isLoggedIn && (
                <div className="single-mobile-header-info">
                  <div className="single-mobile-header-info">
                    <Link to="/login" onClick={() => setToggle(!toggle)}>
                      <i className="fi-rs-user"></i> Log In / Sign Up
                    </Link>
                  </div>
                  {/* <div className="single-mobile-header-info">
                    <a href="tel:+8809613664444">
                      <i className="fi-rs-headphones"></i> +8809613664444
                    </a>
                  </div> */}
                </div>
              )}
              {isLoggedIn && (
                <div>
                  <Link to="/customer/overview" onClick={() => setToggle(!toggle)}>
                    <span className="lable ml-0">{user.customer_name}</span>
                  </Link>

                  {/* ================================================== */}
                  <div className="">
                    <ul>
                      <li>
                        <Link to="/customer/dashboard" onClick={() => setToggle(!toggle)}>
                          <i className="fi fi-rs-user mr-10"></i>My Account
                        </Link>
                      </li>
                      <li>
                        <Link to="/customer/orders" onClick={() => { setToggle(false); console.log(toggle); }}>
                          <i className="fi fi-rs-location-alt mr-10"></i>Orders
                        </Link>
                      </li>
                      <li>
                        <Link to="/wish-list" onClick={() => setToggle(!toggle)}>
                          <i className="fi fi-rs-heart mr-10"></i>My Wishlist
                        </Link>
                      </li>
                      <li>
                        <Link to="/customer/profile" onClick={() => setToggle(!toggle)}>
                          <i className="fi fi-rs-settings-sliders mr-10"></i>Update profile
                        </Link>
                      </li>
                      <li>
                        <Link to="/customer/address" onClick={() => setToggle(!toggle)}>
                          <i className="fi fi-rs-settings-sliders mr-10"></i>Address Book
                        </Link>
                      </li>
                      <li>
                        <Link to="/customer/change-password" onClick={() => setToggle(!toggle)}>
                          <i className="fi fi-rs-settings-sliders mr-10"></i>Change Password
                        </Link>
                      </li>
                      <li>
                        <Link to="#" onClick={logout}>
                          <i className="fi fi-rs-sign-out mr-10"></i>Sign out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>

          </div>
        </div>
        <div className="mobile-social-icon mt-2">
          {/* <h6 className="mb-15">Follow Us</h6> */}

          {props.socialMedia &&
            props.socialMedia.map((media, index) => {
              return (
                <a key={index} className="ms-3 me-3" href={media.url} title={media.title} target="_blank" rel="noopener noreferrer">
                  <img src={media.social_icon && (axios.defaults.baseURL.slice(0, -4) + "frontend/images/social_icons/" + media.social_icon)} alt={media.title} />
                </a>
              );
            })
          }
        </div>
        {/* <div className="site-copyright">
              Copyright 2022 © Ultimate Organic Life
            </div> */}
      </div>

      <div className={searchToggle ? "overlay open" : "overlay"} onClick={() => setSearchToggle(!searchToggle)}>
        <div className="mobile-search search-style-3 mobile-header-border">
          <form
            action=""
            className="form--quick-search"
            data-ajax-url=""
            method="get"
          >
            <input type="text" name="search" className="input-search-product" placeholder="Search for items…" value={productName}
              onChange={(e) => { setProductName(e.target.value); }}
              onClick={(e) => { setProductName(e.target.value); e.stopPropagation(); }} autoComplete='off'
            />
            <button type="submit">
              <i className="fi-rs-search"></i>
            </button>
            <div className={productName ? 'panel--search-result active' : 'panel--search-result'}>
              <div className="panel__content ">
                <div className="row py-2 mx-0 ">
                  {products && products.map((product, index) => {
                    return (
                      <div key={index} className="col-12 px-1 px-md-2 py-1 product-cart-wrap border-0 rounded-0">
                        <div className="row mx-md-2 gx-md-2 gx-1">
                          <div className="col-xl-2 col-3 product-img-action-wrap mb-0">
                            <div className="product-img product-img-zoom">
                              <Link to={"/product_details/" + product.id}>
                                <img className="default-img" src={product.image && (product.image.small)} alt={product} />
                              </Link>
                            </div>
                          </div>
                          <div className="col-xl-10 col-9 product__content">
                            <div className="product-content-wrap px-1 px-md-3">
                              <Link className="product__title" to={"/product_details/" + product.id}>{product.name}</Link>
                              {/* <div className="rating_wrap">
                                  <div className="product-rate d-inline-block">
                                    <div className="product-rating" style={{width: "50%"}}></div>
                                  </div>
                                  <span className="rating_num">(4)</span>
                                </div> */}
                              {product.discount > 0 ? (
                                <div className="product-price">
                                  <span style={{ color: '#2a7737' }}>{product.formatted_final_product_price}</span>
                                  <span className="old-price">{product.formatted_regular_price}</span>
                                </div>
                              ) : (
                                <div className="product-price">
                                  <span style={{ color: '#2a7737' }}>{product.formatted_final_product_price}</span>
                                  {/* <span className="old-price">{product.formatted_regular_price}</span> */}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {products.length <= 0 && (
                    <span className="text-center text-warning">Ops! No Products Found</span>
                  )}
                </div>

              </div>
              <div className="panel__footer text-center">
                {/* <Link to="#">See all results</Link> */}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={loginToggle ? "overlay open item-center" : "overlay"} onClick={() => setLoginToggle(!loginToggle)}>
        <div className="padding_eight_all bg-white modal_login" onClick={(e) => { e.stopPropagation(); }}>
          <Link to="#" onClick={() => setLoginToggle(!loginToggle)}>
            <div className="mobile-menu-close close-style-wrap login-cross">
              <button className="close-style search-close">
                <i className="icon-top"></i>
                <i className="icon-bottom"></i>
              </button>
            </div>
          </Link>
          <div className="heading_s1">
            <h3 className="mb-5">Login</h3>
            <p className="mb-30">
              Don&#039;t have an account? <Link to="/register" onClick={() => setLoginToggle(!loginToggle)}>Create one</Link>
            </p>
          </div>
          <span style={{ color: "red" }}>{error}</span>
          <form
            onSubmit={(e) => {
              loginSubmit(e);
            }}
          >
            <div className="form-group">
              {/*   <input type="text" required name="text" placeholder="Username or Email*" /> */}
              <input
                type="text"
                name="username"
                id="username"
                placeholder="Phone Or Email*"
                required
                value={username}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            {/* */}{" "}
            <div className="form-group">
              {/*  <input type="password" required name="password"  placeholder="Your password*" /> */}
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password*"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="login_footer form-group mb-25">
              <div className="chek-form">
                <div className="custome-checkbox">
                  <input className="form-check-input" type="checkbox" name="remember" id="remember-checkbox" value="" />

                  <label className="form-check-label" htmlFor="remember-checkbox">
                    <span>Remember me</span>
                  </label>
                </div>
              </div>
              <Link className="text-muted" to="/forgot-password">
                Forgot password?
              </Link>
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-heading btn-block hover-up">
                Login
              </button>
            </div>
            <div className="text-left"></div>
          </form>
        </div>
      </div>
      <div className={cartToggle ? "overlay open" : "overlay"} onClick={() => setCartToggle(!cartToggle)}>
        <div className="mobile_cart_new" onClick={(e) => { e.stopPropagation(); }}>
          <div className="mobile_cart_content">
            <div className="mobile_cart_content_nav">
              <h2 style={{ fontSize: '1.6rem' }}>Shopping cart</h2>
              <Link to="#" onClick={() => setCartToggle(!cartToggle)}>
                <div className="mobile-menu-close close-style-wrap" style={{right:'30px',top:'20px'}}>
                  <button className="close-style search-close">
                    <i className="icon-top"></i>
                    <i className="icon-bottom"></i>
                  </button>
                </div>
              </Link>
            </div>
            <div className="mobile_cart_content_body mb-4">
            {totalUniqueItems === 0 && (
                      <span className="p-4 mt-4">No products in the cart.</span>
                    )}
                    {totalUniqueItems !== 0 &&
                      <div>
                        {items.map((item, key) => {
                          return (
                            <div key={key} className='row cart_products'>
                              <div className="col-3 p-0">
                              <Link to={"/product_details/" + item.id}>
                                      <img alt="" src={item.image} />
                              </Link>
                              </div>
                              <div className="col-8">
                              <p className="mobile_cart_product_name mt-sm-3">
                               <Link to={"/product_details/" + item.id} title={item.name}>{item.name} </Link>
                              </p>
                              <span className="d-inline-block mt-2 pe-2 mb-sm-4">Price: ৳{item.price * item.quantity}</span>

                              <div
                              className="quantity_button_group mobile_cart_btn_group">
                              <button
                                className="quantity_button mobile_cart_btn"
                                disabled={item.quantity == 1}
                                onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="2px" viewBox="0 0 12 1.5">
                                  <rect data-name="Rectangle 970" width="12px" height="2px" fill="currentColor"></rect>
                                </svg>
                              </button>
                              <input type='number' className="qty-input quantity_value" value={item.quantity} onChange={(e) => updateItemQuantity(item.id, e.target.value)} />
                              <button
                                className="quantity_button mobile_cart_btn" onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>
                                <svg data-name="plus (2)" xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"
                                  viewBox="0 0 12 12">
                                  <g data-name="Group 5367">
                                    <path data-name="Path 17138"
                                      d="M6.749,5.251V0h-1.5V5.251H0v1.5H5.251V12h1.5V6.749H12v-1.5Z"
                                      fill="currentColor"></path>
                                  </g>
                                </svg>
                              </button>
                            </div>
                              
                              </div>
                              <div className="col-1">
                                
                              <div className="shopping-cart-delete" onClick={() => removeItem(item.id)}>
                                    <a href="#" data-url="" className="remove-cart-item" onClick={() =>
                                      {
                                      //   Swal.fire({
                                      //   position: 'top-end',
                                      //   icon: 'warning',
                                      //   title: 'Product removed form the cart',
                                      //   showConfirmButton: false,
                                      //   timer: 800
                                      // })
                                    }
                                    }>
                                      <i className="text-danger fi-rs-cross-small" style={{ left: '-10px', position: 'relative'}}></i>
                                    </a>
                                  </div>
                              </div>
                              {/* <hr/> */}
                            </div>
                          );
                        })}
                      </div>
                    }
            </div>
            
              <div className="mobile_cart_content_footer p-2">
              <Link to="/checkout" className="btn checkout_btn_mobile">
                <span className="p_to_chkout">Proceed To Checkout</span>
                <span className="p_to_chkout_price"><span className="height_border"></span> | &nbsp;{totalPrice}৳ </span>
                </Link>
              </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default MobileMenu;
