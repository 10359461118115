import React from "react";
import BannerSlider from "./BannerSlider";
import FeactCat from "./featureCat";
import ProductBox3 from "./productBox3";
import Brings from "./brings";
import AutoPlay from "./bringCat";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import FeaturedCategories from "./FeaturedCategories";

const Home = () => {
  const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 1000);
    // }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life - Home";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
      <Layout>

        <BannerSlider />


        <h3 className="p-3 mt-2">
          Shop By Categories
        </h3>
        <FeaturedCategories/>

        <h3 className="p-3 mt-2">
          Popular Products
        </h3>
        <ProductBox3 productType="popular" />


        {/* <div className=""  style={{ padding: "15px" }}> */}
        <h3 className="p-3 mt-2">Daily Best Sells</h3>
        {/* </div> */}
        <div className="row">
          <div className="col-md-3 d-sm-none d-md-block d-lg-block">
            <Brings></Brings>
          </div>
          {/* Daily Best Sells */}
          <div className="col-md-9 col-sm-12 p-0">
            <AutoPlay></AutoPlay>
          </div>
        </div>

        <div>
          <section className="section-padding pb-5">

            <h3 className="p-3 mt-2">Deals Of The Day</h3>
            <ProductBox3 productType="dealsday"></ProductBox3>
          </section>
        </div>

        <div className="mb-md-5"> 
        <FeactCat />
        </div>

      </Layout>
    </div>
  );
};

export default Home;
