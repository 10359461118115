import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from "axios";

const ViewOrder = (props) => {

    const [loading, setLoading] = useState(false);
    const { add_id } = useParams();
    // useEffect(() => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 1000);
    // }, []);

    const [order, setOrder] = useState([]);
    const [cart, setCart] = useState([]);
    const [additional, setAdditional] = useState([]);
    useEffect(() => {
        setLoading(true);
        axios.get("get-order/" + add_id)
            .then(resp => {
                // console.log(resp.data);
                setLoading(false);
                // setCart(null);
                setOrder(resp.data.data);
                var temp = JSON.parse(resp.data.data.cart);
                for(var i in temp){
                    cart.push(temp[i]);
                }

                // setCart(JSON.parse(resp.data.data.cart));
                setAdditional(JSON.parse(resp.data.data.additional));
            }).catch(err => {
                console.log(err);
            });
    }, [add_id]);

    // console.log(order);
    return (
        <div>
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <div className="tab-content account dashboard-content pl-lg-50">
                <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    <div className="card">
                        {order ? (
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="mb-0">Order detail: {order.order_number}</h5>
                                </div>
                                <div className="card-body">
                                    <div className="customer-order-detail">
                                        <div className="row">
                                            <div className="col-auto me-auto">
                                                <div className="order-slogan">
                                                    <img width={50} src={"/assets/images/logo/Original_logo.png"} alt="Ultimate Organic Life" /><br />
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="order-meta">
                                                    <span className="d-inline-block">Time:</span><strong className="order-detail-value"> {order.created_at && (order.created_at.slice(0, 10))}&nbsp;&nbsp;
                                    {order.created_at && (order.created_at.slice(11, 19))}
                                    </strong></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 border-top pt-2 row">
                                                <h4>Order information</h4>
                                                <div>
                                                    <div>
                                                        <span className="d-inline-block">Order status:</span><strong className="order-detail-value">{order.status}</strong>
                                                    </div>
                                                    <div><span className="d-inline-block">Payment method:</span><strong className="order-detail-value"> {order.payment_method} </strong>
                                                    </div>
                                                    <div><span className="d-inline-block">Payment status:</span><strong className="order-detail-value"> {order.payment_status}</strong></div>
                                                    <div><span className="d-inline-block">Amount:</span><strong className="order-detail-value"> ৳{order.subtotal} </strong></div>
                                                    {/* <div><span className="d-inline-block">Tax:</span><strong className="order-detail-value"> ৳{order.vat} </strong></div> */}
                                                    <div><span className="d-inline-block">Discount:</span><strong className="order-detail-value"> {order.coupon ? (order.coupon.coupon_type == 'percentage' ? '%' + order.coupon.discount_amount : '৳' + order.coupon.discount_amount) : '৳0.00'} </strong></div>
                                                    <div><span className="d-inline-block">Shipping fee:</span><strong className="order-detail-value"> ৳{order.shipping_cost ? order.shipping_cost.price : '0.00'} </strong></div>
                                                </div><h4 className="mt-3 mb-1">Receiver Information</h4><div><div><span className="d-inline-block">Full Name:</span><strong className="order-detail-value"> {order.shipping_name} </strong></div>
                                                    <div><span className="d-inline-block">Phone:</span><strong className="order-detail-value"> {order.shipping_phone} </strong></div>
                                                    <div><span className="d-inline-block">Email:</span><strong className="order-detail-value"> {order.shipping_email} </strong></div>
                                                    <div className="row"><div className="col-12 ps-0"><span className="d-inline-block">Address:</span><span className="order-detail-value"> {order.shipping_address} </span>&nbsp; </div>
                                                    </div>
                                                </div>
                                                <h4 className="mt-3 mb-1">Products</h4>
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                {/* <th>Image</th> */}
                                                                <th>Product</th>
                                                                <th>Amount</th>
                                                                <th className="page_speed_1502875340">Quantity</th>
                                                                <th className="price text-right">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {cart && cart.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="align-middle">1</td>
                                                                        {/* <td className="align-middle">
                                                                            <img src="" width="50" alt={item.name} />
                                                                        </td> */}
                                                                        <td className="align-middle"> {item.name} <p>
                                                                            {/* <small> Weight: 2KG, Boxes: 3 Boxes </small> */}
                                                                            </p><p className="d-block mb-0 sold-by">
                                                                            {/* <small>Sold by: <a href="">GoPro</a></small> */}
                                                                        </p>
                                                                        </td>
                                                                        <td className="align-middle">৳{item.price}</td>
                                                                        <td className="align-middle">{item.quantity}</td>
                                                                        <td className="money text-right align-middle"><strong> ৳{item.price * item.quantity} </strong></td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table></div><br />
                                                    {/* <h5>Shipping Information:</h5>
                                                <p><span className="d-inline-block">Shipping Status</span>: <strong className="d-inline-block text-info"><span className="label-warning status-label">Pending</span>
                                                </strong></p>
                                                <div className="mt-2 row"><div className="col-auto me-auto">
                                                    <a href="" className="btn btn-info btn-sm"><i className="fa fa-download"></i> Download invoice</a></div>
                                                    <div className="col-auto"><a href=""className="btn btn-danger btn-sm ml-2">Cancel order</a>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='text-warning'>
                                No Order Found
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ViewOrder;