import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';
import Swal from "sweetalert2";

const ForgetPassword = () => {
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    useEffect(() => {
        document.title = "Ultimate Organic Life - Forgot Password";
    }, []);

    const [error, setError] = useState();
    let [userInput, setUserInput] = useState("");
  
  const sendOTP = () => {
    if(!userInput){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Phone or Email Required!',
        // showConfirmButton: false,
        // timer: 2000
      });
    }
    else{
      axios
        .post("forget-customer-password", {user_input:userInput})
        .then(function (resp) {
            console.log(resp.data);
          if (resp.data.success) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: resp.data.message,
              // showConfirmButton: false,
              // timer: 2000
            }).then(function (response) {
                if(response.isConfirmed){
                    navigate("/verify-otp-set-new-password/"+resp.data.data.customer_id);
                    window.location.reload(false);
                }
            });
          }
          else{
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Invalid Input Or Phone or Email Not Registered',
                // showConfirmButton: false,
                // timer: 2000
              });
            setError(resp.data.message);
          }
        })
        .catch((err) => {
            console.log(err);
          setError(err.response.data.errors);
        });
    }
  }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <Layout>
                <div className="page-header breadcrumb-wrap">
                    <div className="breadcrumb">
                        <Link to="/" itemProp="item" title="Home">
                            Home
                        </Link>
                        <span></span> About Us
                        <span></span>
                    </div>
                </div>
                <br />
                <div className="row justify-content-center">
                    <div className="col-6 pt-1 pb-4">
                        <div class="login_wrap widget-taber-content p-30 background-white border-radius-10">
                            <div class="padding_eight_all bg-white">
                                <div class="heading_s1 mb-20">
                                    <h3 class="mb-30">Reset Password</h3>
                                </div>
                                <div class="form__content">
                                    <div class="form-group">
                                        <label for="txt-email" class="required">Phone Or Email</label>
                                        <input name="email" id="txt-email" type="email" value={userInput} onChange={(e)=> {setUserInput(e.target.value)}} placeholder="Please enter your email address" class="form-control" required />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-fill-out btn-block hover-up" onClick={()=>{ sendOTP(); }}>Send OTP</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        </div>
    );
};

export default ForgetPassword;
