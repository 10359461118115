import React, { useEffect, useState } from "react";
import BrowserCat from "./browserCat";
import NewsFlah from "./newsflash";
import { CartProvider, useCart } from "react-use-cart";
import { NavLink } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CartPopup from "./cartPopup";
import Swal from "sweetalert2";
import Search from "../components/Search";

/* import Cart from "./cart"; */

const TopHeader = (props) => {
  // { numberCart }
  const [show, setShow] = useState(false);
  const [wishlistCount, setWishlistCount] = useState(0);
  const [searchShow, setSearchShow] = useState(true);
  const {
    totalUniqueItems
  } = useCart();
  
  let navigate = useNavigate();
  var isLoggedIn = false;
  var user = JSON.parse(localStorage.getItem("user"));
  // console.log(user);
  if (user) {
    if(user.customer_id){
      isLoggedIn = true;
    }
  }
  
  // console.log(props.user);
  
  useEffect(() => {
    if(isLoggedIn){
      axios.get("get-wishlist/" + user.customer_id)
      .then(resp => {
        // console.log(resp.data.data);
        if(resp.data.success==true){
          setWishlistCount(resp.data.data.length);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, []);



  const logout = () => {
    if (user) {
      axios.post("customer/logout")
      .then(function (resp) {
        // console.log(resp.data);
        if (resp.data.success) {
        localStorage.removeItem("user");
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: 'Successfully logged out',
          showConfirmButton: false,
          timer: 1500
        });
        navigate("/");
        
      }
        // console.log(defaultAddress);
      })
      .catch((err) => {
        console.log(err);
      });
      
    } else {
      navigate("/");
    }
    isLoggedIn=false;
  };
  
  // const sendDataToParent = (index) => { // the callback. Use a better name
  //   setSearchShow(index);
  // };
  // console.log(searchShow);
  
  return (
    <div className="header-area header-style-1 header-height-2 ">
      <div className="header-top header-top-ptb-1 d-none d-lg-block top_underlines">
        {/* <div className="container-fluid"> */}
        <div className="row align-items-center top-news-bar p-0">
          <div className="col-xl-3 col-lg-6 p-0">
            <div className="header-info">
              <ul>
                <li>
                  <Link to="/about-us">
                    <span>About Us</span>
                  </Link>
                </li>
                <li>
                  <Link to="/track-order">
                    <span>Order Tracking</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-xl-6 d-none d-xl-block">
            <div className="text-center">
              <div id="news-flash" className="d-inline-block">
                <ul>
                  <NewsFlah></NewsFlah>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6">
            <div className="header-info header-info-right">
              <ul>
                <li>
                  Need help? Call Us: &nbsp;
                  {props.footerData && (
                  <a href={"tel:"+props.footerData.hotline_no}><strong className="text-brand">{props.footerData.hotline_no}</strong></a>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* ====================>SERACH HEADER */}
        <div className="row ">
          <div className="header-middle header-middle-ptb-1 d-none d-lg-block ">
            {/*  <!-- <div className="container"> --> */}
            <div className="header-wrap">
              <div className="logo logo-width-1">
              {props.footerData ? (
                <Link to="/" className="">
                <img src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/site_logos/" + props.footerData.site_logo} style={{ width: "150px" }} alt="LOGO not found" />
                </Link>
              ) : (
              <Link to="/" className="">
                <img src="/assets/images/logo/Original_logo.png" style={{ width: "150px" }} alt="LOGO not found" />
              </Link>
              )}
              </div>
              <div className="header-right">
                <Search show={searchShow} onSearchClickOutside={() => { setSearchShow(false) }} categories={props.categories} />
                
                <div className="header-action-right">
                  <div className="header-action-2">
                    <div className="header-action-icon-2">
                        <Link to={isLoggedIn ? "/wish-list" : "/login"}>
                          <img className="svgInject" alt="Wishlist" src="/assets/themes/nest/imgs/theme/icons/icon-heart.svg" />
                          <span className="pro-count blue wishlist-count"> {wishlistCount} </span>
                        </Link>
                        <Link to={isLoggedIn ? "/wish-list" : "/login"}>
                          <span className="lable">Wishlist</span>
                        </Link>
                      </div>
                    {/* Cart */}
                    <div className="header-action-icon-2">
                      <Link className="mini-cart-icon" to="/cart">
                        <img alt="Cart" src="/assets/themes/nest/imgs/theme/icons/icon-cart.svg" />
                        <span className="pro-count blue">{totalUniqueItems}</span>
                      </Link>
                      <Link to="/cart">
                        <span className="lable">Cart</span>
                      </Link>
                      {totalUniqueItems === 0 && (
                        <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                          <span>No products in the cart.</span>
                        </div>
                      )}
                      {totalUniqueItems !== 0 && <CartPopup></CartPopup>}
                    </div>
                    <div className="header-action-icon-2">

                      {!isLoggedIn && (
                        <div><Link to="#">
                          <img className="svgInject rounded-circle" alt="Account" src="/assets/themes/nest/imgs/theme/icons/icon-user.svg" />
                        </Link>
                          <Link to="#">
                            <span className="lable ml-0">Account</span>
                          </Link>
                          <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                            <ul>
                              <li>
                                <Link to="/login">
                                  <i className="fi fi-rs-user mr-10"></i>Login
                                </Link>
                              </li>
                              <li>
                                <Link to="/register">
                                  <i className="fi fi-rs-user-add mr-10"></i>Register
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                      {isLoggedIn && (
                        <div>
                          <div className="header-action-icon-2">
                            <Link className="svgInject rounded-circle" to="/customer/dashboard">
                            <img className="svgInject rounded-circle" alt="Account" src="/assets/themes/nest/imgs/theme/icons/icon-user.svg" />
                            </Link>
                            <Link to="/customer/dashboard">
                              <span className="lable ml-0">{user.customer_name.slice(0,12)+'...'}</span>
                              </Link>
                              <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                            <ul>
                                <li>
                                  <Link to="/customer/dashboard">
                                    <i className="fi fi-rs-user mr-10"></i>My Account
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/customer/orders">
                                    <i className="fi fi-rs-location-alt mr-10"></i>Orders
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/wish-list">
                                    <i className="fi fi-rs-heart mr-10"></i>My Wishlist
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/customer/profile">
                                    <i className="fi fi-rs-settings-sliders mr-10"></i>Update profile
                                  </Link>
                                </li>
                              <li>
                                <a href="#" onClick={logout}>
                                  <i className="fi fi-rs-sign-out mr-10"></i>Sign out
                                </a>
                              </li>
                            </ul>
                          </div>
                               </div>

                          {/* ================================================== */}
                          
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
        {/* ====================>SERACH HEADER-2 */}
        <div className="row">
          <div className="header-bottom header-bottom-bg-color  sticky-bar ">
            {/*   <!-- <div className="container"> --> */}
            <div className="header-wrap header-space-between position-relative">
              <div className="logo logo-width-1 d-block d-lg-none">
                <Link to="/">
                  <img src="" alt="Ultimate Organic Ecommerce" />
                </Link>
              </div>

              <div className="header-nav d-none d-lg-flex">
                <div className="main-categories-wrap d-none d-lg-block" onClick={() => setShow(!show)} >
                  <NavLink to="" className="categories-button-active NavLink">
                    <span className="fi-rs-apps"></span> <span className="et">Browse</span> All Categories
                    {show ? <i className="fi-rs-angle-up"></i> : <i className="fi-rs-angle-down"></i>}
                  </NavLink>
                </div>
                {show && (
                  <BrowserCat show={show} onClickOutside={() => { setShow(false) }} categories={props.categories} />
                )}
              </div>

              <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                <nav>
                  <ul>
                    <li>
                      <Link to="/" className={window.location.pathname === '/' ? 'd-none' : 'menu'} target="_self">
                        Home
                      </Link>
                    </li>
                    {/* <li>
                        <Link to="/cateproduct" target="_self">
                          Shop
                        </Link>
                      </li>
                      <li>
                        <Link to="/cateproduct" target="_self">
                          Stores
                        </Link>
                      </li> */}
                    <li>
                      <Link to="/products" target="_self" className={window.location.pathname === '/products' ? 'active' : ''}>
                        All Products
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq" target="_self" className={window.location.pathname === '/faq' ? 'active' : ''}>
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" target="_self" className={window.location.pathname === '/contact' ? 'active' : ''}>
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link to="/blogs" target="_self" className={window.location.pathname === '/blogs' ? 'active' : ''}>
                        Blogs
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="hotline d-none d-lg-flex">
                <img src="/assets/images/topheader/icon-headphone.svg" alt="hotline" />
                <p>
                {props.footerData && (
                  <>
                  <a href={"tel:"+props.footerData.hotline_no}>{props.footerData.hotline_no}</a>
                  <span>{props.footerData.hotline_description}</span></>
                  )}
                </p>
              </div>
              <div className="header-action-icon-2 d-block d-lg-none">
                <div className="burger-icon burger-icon-white">
                  <span className="burger-icon-top"></span>
                  <span className="burger-icon-mid"></span>
                  <span className="burger-icon-bottom"></span>
                </div>
              </div>
              <div className="header-action-right d-block d-lg-none">
                <div className="header-action-2">
                  <div className="header-action-icon-2">
                    <Link to="#/compare">
                      <img alt="Compare" src="/assets/images/topheader/icon-compare.svg" />
                      <span className="pro-count white compare-count">0</span>
                    </Link>
                  </div>
                  <div className="header-action-icon-2">
                    <Link to="#/wishlist">
                      <img alt="Wishlist" src="/assets/images/topheader/icon-heart.svg" />
                      <span className="pro-count white wishlist-count"> 0 </span>
                    </Link>
                  </div>
                  <div className="header-action-icon-2">
                    <Link className="mini-cart-icon" to="#">
                      <img alt="Cart" src="/assets/images/topheader/icon-cart.svg" />
                      <span className="pro-count white">0</span>
                    </Link>
                    <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                      <span>No products in the cart.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default React.memo(TopHeader); 
