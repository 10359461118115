import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../layouts/Layout';
import Cabbage from '../assests/images/cabbage.png'
import axios from "axios";
import Dashboard from '../components/Dashboard';
import Profile from '../components/Profile';
import Orders from '../components/Orders';
import AddressBook from '../components/AddressBook';
import ChangePassword from '../components/ChangePassword';
import AddAddress from '../components/AddAddress';
import EditAddress from '../components/EditAddress';
import ViewOrder from '../components/ViewOrder';

const CustomerDashboard = () => {

  useEffect(() => {
    // console.log("Full render")
    document.title = "Ultimate Organic Ecommerce - My Account";
  }, []);
  const [loading, setLoading] = useState(false);
  const { pgType } = useParams();
  // const [pageType, setPageType] = useState('dashboard');
  // console.log(pgType);
  // if(pgType){
  //   setPageType(pgType);
  // }
  useEffect(() => {
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);
  }, []);
  var user = JSON.parse(localStorage.getItem("user"));
  // console.log(user);
  var customer_id = null;
  if (user) {
    customer_id = user.customer_id;
  }
  const [userData, setUserData] = useState(user.user);
  /** Used to avoid multiple api calls */
  // useEffect(() => {
  //   if (!isApiCalled) {
  //     axios
  //       .get("get-edit-customer/" + customer_id)
  //       .then(function (resp) {
          
  //         isApiCalled = true;
  //         setLoading(false);
  //         setUserData(resp.data);
  //         setApiData(resp.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         isApiCalled = false;
  //       });
  //   }
  // }, [pgType]);
  const [apiData, setApiData] = useState("");
  let navigate = useNavigate();
  /*   var isLoggedIn = false; */

  const logout = () => {
    if (user) {
      /* user = JSON.parse(localStorage.getItem("user")); */

      var obj = { token_id: user.token_id };
      if (axios.post("customer/logout")) {
        localStorage.removeItem("user");

        navigate("/"); //To redirect to Homepage
        /*  window.setTimeout(function () {
          window.location.reload();
        }, 200); */
      }
    } else {
      navigate("/");
    }
    /*   isLoggedIn = false; */
  };
  const [orders, setOrders] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState([]);
  const [apiProfileData, setApiProfileData] = useState([]);
  
  useEffect(() => {
    // console.log(pgType);
      if(pgType==='orders'){
        setLoading(true);
        axios.get("get-customer-orders/" + customer_id)
          .then(resp => {
            // console.log("API repsonse", resp)
            // console.log(resp.data);
            setLoading(false);
            // isApiCalled.current = true;
            setOrders(resp.data);
          }).catch(err => {
            console.log(err);
          });
      }
      if(pgType==='address'){
        setLoading(true);
        axios
        .get("get-customer-addresses/" + customer_id)
        .then(function (resp) {
          // isApiCalled.current = true;
          // console.log("API repsonse", resp)
          // console.log(resp.data);
          setLoading(false)
          if(resp.data.data){
            setAddresses(resp.data.data);
            setDefaultAddress(resp.data.data.find(address => address.is_default === 1));
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
      if(pgType==='profile'){
        setLoading(true);
        axios
        .get("get-edit-customer/" + customer_id)
        .then(function (resp) {
          // isApiCalled.current = true;
          // console.log("API repsonse")
          // console.log(resp.data);
          setLoading(false)
          if(resp.data){
            setApiProfileData(resp.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
  }, [pgType]);

  
  console.log();
  // console.log(userData);

  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb" itemType="http://schema.org/BreadcrumbList">
              <Link to="/" itemProp="item" title="Home">
                Home
                <meta itemProp="name" content="Home" />
                <meta itemProp="position" content="1" />
              </Link>
              <span></span> Account information
              <meta itemProp="name" content="Account information" />
              <meta itemProp="position" content="2" />
            </div>
          </div>
        </div>
        <div className="page-content pt-30 pb-150">
          <div className="row">
            <div className="col-lg-12 m-auto">
              <div className="row">
                <div className="col-md-3">
                  <div /* className="page-content pt-150 pb-150" */>

                    <div className="row">
                      <div className="col-4">
                        {/* <img className='img-fluid' src={Cabbage} alt="User" /> */}
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="profile-usertitle-name pt-2">
                        <strong>{userData.customer_name}</strong>{" "}
                        <p>
                          <small>
                            {userData.customer_email}
                            {/* {console.log("Email: " + .customer_email)} */}
                          </small>
                        </p>
                      </div>
                    </div>

                    <hr />
                    <div className="dashboard-menu">
                      <ul className="nav flex-column">
                        <li className="nav-item">
                          <Link
                            to="/customer/dashboard"
                            className={pgType==='dashboard'  ? 'nav-link active' : 'nav-link'}>
                            <i className="fi-rs-settings-sliders mr-10" aria-hidden="true"></i>
                            Dashboard
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/customer/profile"
                            className={pgType==='profile' ? 'nav-link active' : 'nav-link'}>
                            <i className="fi-rs-edit-alt mr-10" aria-hidden="true"></i>
                            Profile
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/customer/orders" className={pgType==='orders' || pgType==='order' ? 'nav-link active' : 'nav-link'}>
                            <i className="fi-rs-shopping-bag mr-10" aria-hidden="true"></i>
                            Orders
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/customer/address" className={pgType==='address' || pgType==='add-address' || pgType==='edit-address' ? 'nav-link active' : 'nav-link'}>
                            <i className="fi-rs-marker mr-10" aria-hidden="true"></i>
                            Address books
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link to=" " className="nav-link" aria-controls="dashboard" aria-selected="false">
                            <i className="fi-rs-heart mr-10" aria-hidden="true"></i>
                            Wishlist
                          </Link>
                        </li> */}
                        <li className="nav-item">
                          <Link
                            to="/customer/change-password"
                            className={pgType==='change-password' ? 'nav-link active' : 'nav-link'}
                            aria-controls="dashboard"
                            aria-selected="false"
                          >
                            <i className="fi-rs-key mr-10" aria-hidden="true"></i>
                            Change password
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/login"
                            className="nav-link"
                            aria-controls="dashboard"
                            aria-selected="false"
                            onClick={logout}
                          >
                            <i className="fi-rs-sign-out mr-10" aria-hidden="true"></i>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-9">
                  {pgType==='dashboard' && (
                  <Dashboard customer_name={userData.customer_name} />
                  )}
                  {pgType==='profile' && apiProfileData.customer_contact && (
                  <Profile userData={userData} apiProfileData={apiProfileData} />
                  )}
                  {pgType==='orders' && orders.length>0 && (
                  <Orders customer_id={customer_id} orders={orders} />
                  )}
                   {pgType==='orders' && orders.length<=0 && (
                    <h6>You Haven't Ordered Anything Yet</h6>
                  )}
                  {pgType==='address' && (
                  <AddressBook customer_id={customer_id} customer_name={userData.customer_name} defaultAddress={defaultAddress} addresses={addresses}  />
                  )}
                  {pgType==='change-password' && (
                  <ChangePassword customer_id={customer_id} />
                  )}
                  {pgType==='add-address' && (
                  <AddAddress customer_id={customer_id} />
                  )}
                  {pgType==='edit-address' && (
                  <EditAddress customer_id={customer_id} />
                  )}
                  {pgType==='order' && (
                  <ViewOrder customer_id={customer_id} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>

    </div>
  );
}

export default CustomerDashboard;