import React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { CartProvider, useCart } from "react-use-cart";
import Layout from "../layouts/Layout";
import Swal from 'sweetalert2'

const BrowserPage = () => {
  let navigate = useNavigate();
  const { addItem } = useCart();
  const [count, setCount] = useState();
  const { id } = useParams();
  const { cname } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");
  const [query, setQuery] = useState('');
  useEffect(() => {
    setQuery('?sort_by=' + sortBy.slice(0, -2) + '&sort_type=' + sortType);
  }, [sortBy, sortType]);


  useEffect(() => {
    async function callApi() {
      const apiResult = await axios.get("get-category-products/" + id + query);
      console.log(apiResult.data.data.data);
      setProducts(apiResult.data.data.data);
    }
    callApi();
    //  console.log('category paaaaaaaaaaaa'); 
    //  console.log(apiData); 
    window.scrollTo(0, 0);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [id, query]);

  var customer_id;
if (localStorage.getItem("user")) {
  var obj = JSON.parse(localStorage.getItem("user"));
  customer_id = obj.customer_id;
  // console.log(obj);
}
const addToWishlist = (pID) => {
  if(customer_id==null){
    navigate("/login?q=You%20Have%20To%20Login%20First%20For%20Wishlist");
    window.location.reload(false);
  }
  else{
    axios.get("add-to-wishlist/" + customer_id + "/" + pID)
      .then(resp => {
        // console.log(resp.data.success);
        if(resp.data.success==true){
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Product added to wishlist',
            showConfirmButton: false,
            timer: 800
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // console.log(pID);
}
  // console.log(apiData.length);

  return (
    <div className="">
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <main className="main" id="main-section">
          <div className="page-header breadcrumb-wrap">

            <div className="breadcrumb">
              <Link to="/" title="Home">
                Home
                <meta itemProp="name" content="Home" />
                <meta itemProp="position" content="1" />
              </Link>
              <span></span>
              <Link to="/products" itemProp="item" title="Products">
                Products
                <meta itemProp="name" content="Products" />
                <meta itemProp="position" content="2" />
              </Link>
              <span></span> {cname}
              <meta itemProp="name" content={cname} />
              <meta itemProp="position" content="3" />
            </div>

          </div>
          <div className="mb-30" style={{ marginTop: "50px" }}>
            <div className="row">
              <div className="col-lg-12 m-auto">
                <div className="col-lg-12 m-auto my-5">
                  {/* <BrowserPageFilter></BrowserPageFilter> */}
                </div>
                <div className="products-listing position-relative">
                  <div className="list-content-loading">
                    <div className="half-circle-spinner">
                      <div className="circle circle-1"></div>
                      <div className="circle circle-2"></div>
                    </div>
                  </div>
                  <div className="shop-product-filter">
                    <div className="total-product">
                      <p>
                        We found <strong className="text-brand">{products && (products.length)}</strong> items for you!
                      </p>
                    </div>
                    <div className='row d-flex flex-row-reverse mb-4'>
                      <div className='col-4'>
                        <div className='row justify-content-end'>
                          <div className='col-4'> <select className="form-control"
                            name="show" onChange={(e) => { setShow(e.target.value); }}>
                            <option value={null}><i className="fi-rs-apps-sort">Show</i></option>
                            <option value={'12'}>12</option>
                            <option value={'24'}>24</option>
                            <option value={'36'}>36</option>
                            <option value={'48'}>48</option>
                            <option value={'60'}>60</option>
                          </select>
                          </div>
                          <div className='col-6'>
                            <select className="form-control"
                              name="city"
                              value={sortBy} onChange={(e) => { setSortBy(e.target.value); setSortType(e.target.childNodes[e.target.selectedIndex].getAttribute('type')); }}>
                              <option value={null} type={null}>Sort by</option>
                              <option value={'id-n'} type={'desc'}>Newest</option>
                              <option value={'id-o'} type={'asc'}>Oldest</option>
                              <option value={'product_name-a'} type={'asc'}>Name: (A-Z)</option>
                              <option value={'product_name-d'} type={'desc'}>Name: (Z-A)</option>
                              <option value={'product_price-l'} type={'asc'}>Price:low to high</option>
                              <option value={'product_price-h'} type={'desc'}>Price:high to low</option>
                              {/* <option value={'rating'} type={'desc'}>Most Rated</option> */}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ========================================= */}
                  <div className="row product-grid-4 ">
                    {products &&
                      products.map((product, index) => {
                        return (
                          <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5 mb-sm-5" key={index}>
                <div
                  data-wow-delay="0.1s"
                  className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                  style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                >
                  <div className="product-img-action-wrap">
                    <div className="product-img product-img-zoom">
                      <Link to={"/product_details/" + product.id + '/' + product.slug}>
                        {product.image && (

                          <img
                            src={product.image.medium}
                            alt="not found img"
                            className="default-img"
                          />
                        )}
                      </Link>
                    </div>
                    <div className="product-action-1">
                        
                        <Link aria-label="Add To Wishlist" to="" data-url="#" className="action-btn hover-up js-add-to-wishlist-button"
                        onClick={() =>
                          addToWishlist(product.id)
                        }>
                          <i className="fi-rs-heart"></i>
                        </Link>
                       
                      </div>
                    {/* discount percentage */}
                    {product.discount > 0 && (
                      <div className="product-badges product-badges-position product-badges-mrg">
                        <span className="hot">{product.discount}%</span>
                      </div>
                    )}
                  </div>
                  <div className="product-content-wrap">
                    <h2>
                      <Link to={"/product_details/" + product.id + '/' + product.slug}>{product.name}</Link>
                    </h2>
                    <div className="product-rate-cover">
                      <div className="product-rate d-inline-block">
                        <div className="product-rating" style={{ width: "80%" }}></div>
                      </div>
                      <span className="font-small ml-5 text-muted"> (3)</span>
                    </div>

                    <div className="product-card-bottom row">
                      <div className="col-12 col-md-6 text-center">
                        {product.discount > 0 ? (
                          <div className="product-price">
                            <span>৳{Math.ceil(product.discounted_price)}</span>
                            <span className="old-price">৳{Math.ceil(product.final_product_price)}</span>
                          </div>
                        ) : (
                          <div className="product-price">
                            <span>৳{product.final_product_price}</span>
                            {/* <span className="old-price">৳{product.final_product_price}</span> */}
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-md-6 text-center">
                        <div
                          className="add-cart"
                          onClick={() =>
                            addItem({
                              id: product.id,
                              name: product.name,
                              price: product.discounted_price,
                              quantity: 1,
                              image: product.image.small,
                            })
                          }
                        >
                          <Link aria-label="Add To Cart" data-id="2" data-url="#" to="" className="action-btn add-to-cart-button add" onClick={() =>
                            Swal.fire({
                              position: 'top-end',
                              icon: 'success',
                              title: 'Product added to cart',
                              showConfirmButton: false,
                              timer: 800
                            })
                          }>
                            <i className="fi-rs-shopping-cart mr-5"></i>Add
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                        );
                      })}
                  </div>
                  {/* ========================================== */}
                  <br />
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </div>
  );
};
export default BrowserPage;
