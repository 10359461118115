import React, { Component } from "react";
import TopHeader from "../topHeader";
/* import ProductBox from '../productBox'; */
import Dashboard from "./dashboard";
import Footer from "../Footer";
const ChangePass = () => {
  return (
    <div>
      <title>HI</title>
      <div>
        <TopHeader></TopHeader>
      </div>
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
            <a href="/" itemprop="item" title="Home">
              Home
              <meta itemprop="name" content="Home" />
              <meta itemprop="position" content="1" />
            </a>
            <span></span> Change Password
            <meta itemprop="name" content="Account information" />
            <meta itemprop="position" content="2" />
          </div>
        </div>
      </div>
      <div
        className="page-content pt-30 pb-150"
        style={
          {
            /* display: "flex", padding: "200px 40px 200px 200px" */
          }
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <div className="col-md-3">
                  <Dashboard></Dashboard>
                </div>

                <div class="col-md-9">
                <div className="tab-content account dashboard-content pl-50">
            <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
              <div className="card">
                <div className="card">
                  <div className="card-header">
                    <h5>Change password</h5>
                  </div>
                  <div className="card-body">
                    <form method="POST" action="https://nest.botble.com/customer/change-password" accept-charset="UTF-8">
                      <input name="_token" type="hidden" value="3YJhudxqVbQtddq2gBdpTDkqPnB5O158QEDwVeB2" />
                      <div className="form-group ">
                        <label className="required" for="old_password">
                          Current password:
                        </label>
                        <input
                          required
                          type="password"
                          className="form-control square"
                          name="old_password"
                          id="old_password"
                          placeholder="Current password"
                        />
                      </div>
                      <div className="form-group ">
                        <label className="required" for="password">
                          New password:
                        </label>
                        <input required type="password" className="form-control square" name="password" id="password" placeholder="New password" />
                      </div>
                      <div className="form-group ">
                        <label className="required" for="password_confirmation">
                          Password confirmation:
                        </label>
                        <input
                          required
                          type="password"
                          className="form-control square"
                          name="password_confirmation"
                          id="password_confirmation"
                          placeholder="Password confirmation"
                        />
                      </div>
                      <div className="col-md-12">
                        <button type="submit" className="btn btn-fill-out submit">
                          Change password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default ChangePass;
