import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "axios";


function SampleNextArrow(props) {
  const { className, onClick } = props;
  const style = props.style;
  return <div className={className} style={{ ...style, top: "8rem !important", background: "black" }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, "top": "12rem !important", background: "black" }} onClick={onClick} />;
}

const FeactCat = () => {

  const [homepageAdvertisements, setHomepageAdvertisements] = useState([]);
  useEffect(() => {
    axios.get("homepage-advertisement")
      .then(resp => {
        setHomepageAdvertisements(resp.data.advertisement);
      });

  }, []);

  // console.log(homepageAdvertisements);
  var settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow style={{ top: "8rem !important" }} />,
    prevArrow: <SamplePrevArrow style={{ top: "8rem !important" }} />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="">
      <section className="banners pt-20">

        {/* <h3 style={{ padding: "15px" }} className="t2">Featured Categories</h3> */}
        <div className="row p-0">
          <Slider {...settings} className="p-0">
            {/* <div className={index===0 ? "slider-gap ps-0" : "slider-gap"}> */}
            {homepageAdvertisements &&
              homepageAdvertisements.map((homepageAdvertisement, index) => {
                return (

                  <div key={index} className={"slider-gap"}>
                    {/* <div className="col-lg-4 col-md-6"> */}
                    <div className="banner-img wow animate__animated animate__fadeInUp " data-wow-delay="0.2">
                      <img src={homepageAdvertisement.advertisement_image && (axios.defaults.baseURL.slice(0, -4) + "frontend/images/advertisement_images/" + homepageAdvertisement.advertisement_image)} alt="Everyday Fresh" 
                      // style={{height:'14.8rem'}}
                       />
                      <div className="banner-text">
                        <h4>
                          {homepageAdvertisement.title}
                        </h4>
                        <Link to={homepageAdvertisement.url ? homepageAdvertisement.url : '#'} className="btn btn-xs">
                          Shop now <i className="fi-rs-arrow-small-right"></i>
                        </Link>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                );
              })}
           
          </Slider>
        </div>
        {/* <div className="slider-gap">
              <div className="col-lg-4 col-md-6">
              <div className="banner-img wow animate__animated animate__fadeInUp " data-wow-delay="0.4">
                <img src="assets/images/featured/2.png" alt="Make your Breakfast" />
                <div className="banner-text">
                  <h4>Make your Breakfast Healthy and Easy</h4>
                  <Link to="/products" className="btn btn-xs">
                    Shop now <i className="fi-rs-arrow-small-right"></i>
                  </Link>
                </div>
              </div>
              </div>
            </div>
            <div className="slider-gap">
              <div className="col-lg-4 col-md-6">
              <div className="banner-img wow animate__animated animate__fadeInUp " data-wow-delay="0.6">
                <img src="assets/images/featured/3.png" alt="The best Organic" />
                <div className="banner-text">
                  <h4>The best Organic Products Online</h4>
                  <Link to="/products" className="btn btn-xs">
                    Shop now <i className="fi-rs-arrow-small-right"></i>
                  </Link>
                </div>
              </div>
              </div>
            </div> */}
      </section>
    </div>
  );
}

export default FeactCat;
